import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import useLocalStorage from "react-use-localstorage";
import {
  START_NORTHMILL_TRANSACTIONS_SYNC,
  START_ROCKER_TRANSACTIONS_SYNC,
  START_SWEDBANK_TRANSACTIONS_SYNC,
} from "../../graphql/graphql";
import { StartRockerTransactionsSync } from "../../graphql/__generated__/StartRockerTransactionsSync";
import {
  StartSwedbankTransactionsSync,
  StartSwedbankTransactionsSyncVariables,
} from "../../graphql/__generated__/StartSwedbankTransactionsSync";
import { BankSyncButton } from "../bankconnections/BankSyncButton";
import { ConnectionsTable } from "../bankconnections/ConnectionsTable";
import { LunarCsvUploadDialog } from "../bankconnections/LunarCsvUploadDialog";
import { StartNorthmillTransactionsSync } from "../../graphql/__generated__/StartNorthmillTransactionsSync";

export const TransactionsConnectionsTable: React.FC = React.memo(() => {
  const [isLunarDialogOpen, setIsLunarDialogOpen] = useState(false);

  const [personalSecurityNumber] = useLocalStorage("personalSecurityNumber");
  const [startRockerTransactionsSync] =
    useMutation<StartRockerTransactionsSync>(START_ROCKER_TRANSACTIONS_SYNC);
  const [startSwedbankTransactionsSync] = useMutation<
    StartSwedbankTransactionsSync,
    StartSwedbankTransactionsSyncVariables
  >(START_SWEDBANK_TRANSACTIONS_SYNC, {
    variables: { personalSecurityNumber: personalSecurityNumber || null },
  });
  const [startNorthmillTransactionsSync] =
    useMutation<StartNorthmillTransactionsSync>(
      START_NORTHMILL_TRANSACTIONS_SYNC
    );

  const startSwedbankBankIdSync = async () => {
    const url = (await startSwedbankTransactionsSync()).data
      ?.swedbank_transactions_sync.bankIdUrl!;
    window.location.href = url;
  };

  const startRockerBankIdSync = async () => {
    const url = (await startRockerTransactionsSync()).data
      ?.rocker_transactions_sync.bankIdUrl!;
    window.location.href = url;
  };

  const openLunarDialog = () => setIsLunarDialogOpen(true);

  const startNorthmillBankIdSync = async () => {
    const url = (await startNorthmillTransactionsSync()).data
      ?.northmill_transactions_sync.bankIdUrl!;
    window.location.href = url;
  };

  return (
    <>
      <ConnectionsTable>
        <BankSyncButton
          imgUrl="/swedbank-144x144.png"
          imgAltText="Swedbank logo"
          name="Swedbank"
          onClick={startSwedbankBankIdSync}
        />
        <BankSyncButton
          name="Rocker"
          imgUrl="/rocker-144x144.png"
          imgAltText="Rocker logo"
          onClick={startRockerBankIdSync}
        />
        <BankSyncButton
          name="Lunar"
          imgUrl="/lunar-144x144.png"
          imgAltText="Lunar logo"
          onClick={openLunarDialog}
        />
        <BankSyncButton
          name="Northmill"
          imgUrl="/northmill-144x144.png"
          imgAltText="Northmill logo"
          onClick={startNorthmillBankIdSync}
        />
      </ConnectionsTable>
      {isLunarDialogOpen && (
        <LunarCsvUploadDialog onClose={() => setIsLunarDialogOpen(false)} />
      )}
    </>
  );
});
