import React, { useState } from "react";
import { LoadingSpinner } from "../LoadingSpinner";

import "./AsyncOperationButton.css";

type Props = {
  onClick?: (e: React.UIEvent) => Promise<void>;
  text: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const AsyncOperationButton: React.FC<Props> = ({
  onClick,
  text,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick =
    onClick &&
    (async (e: React.UIEvent) => {
      setIsLoading(true);
      await onClick(e);
      setIsLoading(false);
    });

  return (
    <div className="async-operation-button">
      {isLoading ? (
        <LoadingSpinner size="small" />
      ) : (
        <button onMouseDown={handleClick} onTouchStart={handleClick} {...rest}>
          {text}
        </button>
      )}
    </div>
  );
};
