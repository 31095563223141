import React from "react";
import Select from "react-select";
import { TransactionCategory } from "../../state/spending/state";
import { getCategoryLabel } from "../../state/spending/transactionCategoryLabels";
import { CSSObject } from "@emotion/serialize";
import { categories_enum } from "../../__generated__/graphql-global-types";
import { OptionType, customStyles } from "../common/reactSelect";
import { useColors } from "../common/hooks";

export type Props = {
  value: TransactionCategory;
  onChange: (newValue: TransactionCategory) => void;
  style?: Partial<CSSObject>;
};

export const TransactionCategorySelect: React.FunctionComponent<Props> = ({
  value,
  onChange,
  style,
}) => {
  const colors = useColors();
  const options: OptionType[] = Object.keys(categories_enum).map((value) => ({
    value,
    label: getCategoryLabel(
      categories_enum[value as keyof typeof categories_enum]
    ),
  }));

  const selectedValue: OptionType = {
    value: value as string,
    label: getCategoryLabel(value),
  };

  const handleChange = (option: OptionType | null) => {
    if (option !== null) {
      onChange(categories_enum[option.value as keyof typeof categories_enum]);
    }
  };

  return (
    <Select
      styles={customStyles(colors, style || {})}
      value={selectedValue}
      options={options}
      onChange={handleChange}
      blurInputOnSelect={true}
      isSearchable={false}
    />
  );
};
