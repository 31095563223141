import React, {
  PropsWithChildren,
  useCallback,
  useReducer,
  useRef,
} from "react";
import { reducer } from "./state/appReducer";
import { SpendingViewContainer } from "./views/spending/SpendingViewContainer";
import { Error } from "./components/common/Error";
import { AssetsView } from "./views/assets/AssetsView";
import { SpendingAction } from "./state/spending/state";
import { AssetAction } from "./state/assets/state";
import { ExpandablePanel } from "./components/common/ExpandablePanel/ExpandablePanel";
import { AppMenu } from "./components/app/AppMenu";
import { AppFooter } from "./components/app/AppFooter";
import { getInitialState } from "./state/appState";
import { ApolloError } from "@apollo/client";
import { SettingsView } from "./views/SettingsView";

import "./App.css";

export const App: React.FunctionComponent = () => {
  const numberOfBarsToDisplay = window.innerWidth >= 600 ? 12 : 6;

  const [state, dispatch] = useReducer(
    reducer,
    { numberOfBarsToDisplay },
    getInitialState
  );

  const spendingDispatch = useCallback(
    (action: SpendingAction) => {
      dispatch({ slice: "Spending", action });
    },
    [dispatch]
  );

  const assetDispatch = useCallback(
    (action: AssetAction) => {
      dispatch({ slice: "Assets", action });
    },
    [dispatch]
  );

  const onApolloError = useCallback((error: ApolloError) => {
    console.error(error);
    return <Error />;
  }, []);

  const expandablePanelRef = useRef<ExpandablePanel>(null);

  const ConnectedAppFooter: React.FunctionComponent<PropsWithChildren> =
    React.memo(({ children }) => (
      <AppFooter expandablePanelRef={expandablePanelRef} children={children} />
    ));

  const renderActiveView = useCallback(() => {
    switch (state.activeView.type) {
      case "Spending": {
        return (
          <SpendingViewContainer
            activeView={state.activeView.value}
            state={state.spendingState}
            dispatch={spendingDispatch}
            onError={onApolloError}
            Footer={ConnectedAppFooter}
          />
        );
      }
      case "Assets": {
        return (
          <AssetsView
            state={state.assetState}
            dispatch={assetDispatch}
            onError={onApolloError}
            Footer={ConnectedAppFooter}
          />
        );
      }
      case "Settings": {
        return <SettingsView Footer={ConnectedAppFooter} />;
      }
    }
  }, [
    state,
    spendingDispatch,
    assetDispatch,
    onApolloError,
    ConnectedAppFooter,
  ]);

  return (
    <div className="App">
      {renderActiveView()}
      <AppMenu
        state={state}
        dispatch={dispatch}
        expandablePanelRef={expandablePanelRef}
      />
    </div>
  );
};
