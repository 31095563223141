import { AssetListingsSubscription_asset_listings } from "../../graphql/__generated__/AssetListingsSubscription";
import { AssetsQuery_assets } from "../../graphql/__generated__/AssetsQuery";

export type AssetState = {
  selectedAssetListingId: number | null;
};

export type AssetAction = {
  type: "SelectAssetListingId";
  value: number;
};

export type AssetListing = AssetListingsSubscription_asset_listings;
export type Asset = AssetsQuery_assets;

export const getInitialState = (): AssetState => ({
  selectedAssetListingId: null,
});
