import React, { CSSProperties, PropsWithChildren } from "react";
import { Property } from "csstype";

import "./ExpandablePanel.css";
import classNames from "classnames";

export type ExpandDirection = "left" | "right";

type Props = {
  expandedWidth: Property.Width<string | number>;
  expandDirection: ExpandDirection;
  showCloseButton?: boolean;
  closeButtonStyle?: CSSProperties;
  className?: string;
};

type State = {
  isOpen: boolean;
};

export class ExpandablePanel extends React.Component<
  PropsWithChildren<Props>,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = { isOpen: false };
  }

  close = () => {
    this.setState({ isOpen: false });
  };

  open = () => {
    this.setState({ isOpen: true });
  };

  private onMouseDownOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    this.close();
    event.preventDefault();
    event.stopPropagation();
  };

  private handleKeyUp = (event: KeyboardEvent) => {
    const { key } = event;
    switch (key) {
      case "Escape":
        this.setState({ isOpen: false });
        break;
    }
  };

  componentDidMount() {
    document.addEventListener("keyup", this.handleKeyUp);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleKeyUp);
  }

  render() {
    const width = this.props.expandedWidth;
    const panelClassName = classNames(
      {
        "expandable-panel": true,
        "expandable-panel-open": this.state.isOpen,
        "expandable-panel-left": this.props.expandDirection === "left",
        "expandable-panel-right": this.props.expandDirection === "right",
      },
      this.props.className
    );

    const showCloseButton =
      this.props.showCloseButton === undefined
        ? true
        : this.props.showCloseButton;

    return (
      <>
        {this.state.isOpen ? (
          <div
            className="expandable-panel-overlay"
            onMouseDown={this.onMouseDownOutside}
          />
        ) : null}

        <div
          style={{ width: this.state.isOpen ? width : "0" }}
          className={panelClassName}
        >
          <div className="expandable-panel-inner" style={{ width }}>
            {showCloseButton ? (
              <button
                className="expandable-panel-close-button"
                style={this.props.closeButtonStyle}
                onMouseDown={this.close}
              >
                ×
              </button>
            ) : null}
            {this.props.children}
          </div>
        </div>
      </>
    );
  }
}
