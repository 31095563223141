import React, { useCallback } from "react";
import _ from "lodash";
import { PieChart, Pie, Label, Cell, ResponsiveContainer } from "recharts";
import { currencyFormat } from "../functions";
import { Slice } from "../types";

export type Props = {
  series: readonly Slice[];
  labelBottom?: string;
  sliceColor: (slice: Slice, index: number) => string;
  onMouseDown?: (slice: Slice) => void;
};

export const PieChartArea: React.FunctionComponent<Props> = ({
  series,
  labelBottom,
  sliceColor,
  onMouseDown,
}) => {
  const sum = _.sumBy(series, "value");
  const handleOnMouseDown = useCallback(
    (arg: any) => onMouseDown && onMouseDown(arg),
    [onMouseDown]
  );

  const seriesOrdered = _.orderBy(series, (s) => s.value, "desc");

  return (
    <ResponsiveContainer>
      <PieChart margin={{ top: 25, bottom: -130, left: 0, right: 0 }}>
        <Pie
          dataKey="value"
          isAnimationActive={false}
          data={seriesOrdered}
          innerRadius={120}
          outerRadius={160}
          startAngle={180}
          endAngle={0}
          paddingAngle={0.5}
          strokeWidth={0}
          nameKey="name"
          onMouseDown={handleOnMouseDown}
        >
          {seriesOrdered.map((slice, index) => (
            <Cell
              key={slice.id}
              className="pie-slice"
              fill={sliceColor(slice, index)}
            />
          ))}
          <Label
            className="pie-label-center"
            value={`${currencyFormat.format(sum)}`}
            position="center"
          />
          <Label
            className="pie-label-bottom"
            value={labelBottom}
            position="centerBottom"
          />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
