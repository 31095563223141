/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum asset_types_enum {
  CashSEK = "CashSEK",
  Crypto = "Crypto",
  EquityFund = "EquityFund",
  Gold = "Gold",
  HousingCooperative = "HousingCooperative",
  Silver = "Silver",
  Stock = "Stock",
}

export enum categories_enum {
  Alcohol = "Alcohol",
  Bars = "Bars",
  Bike = "Bike",
  BooksAndGames = "BooksAndGames",
  CarAndFuel = "CarAndFuel",
  ClothesAndAccessories = "ClothesAndAccessories",
  Electricity = "Electricity",
  Electronics = "Electronics",
  Events = "Events",
  ExplicitSavings = "ExplicitSavings",
  FurnitureAndInterior = "FurnitureAndInterior",
  Garden = "Garden",
  Gifts = "Gifts",
  Groceries = "Groceries",
  HairDresser = "HairDresser",
  Healthcare = "Healthcare",
  Hobbies = "Hobbies",
  Insurance = "Insurance",
  Kitchen = "Kitchen",
  Lunch = "Lunch",
  Mortage = "Mortage",
  Parking = "Parking",
  Pharmacy = "Pharmacy",
  PublicTransport = "PublicTransport",
  RenovationAndRepair = "RenovationAndRepair",
  Rent = "Rent",
  ResidualSavings = "ResidualSavings",
  Resturants = "Resturants",
  Services = "Services",
  Snacks = "Snacks",
  SportsAndFitness = "SportsAndFitness",
  Taxi = "Taxi",
  Transfers = "Transfers",
  TransportationServices = "TransportationServices",
  Unknown = "Unknown",
  Vacation = "Vacation",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
