import { CSSObject } from "@emotion/serialize";
import { StylesConfig } from "react-select";
import { Colors } from "./hooks";

export type OptionType = {
  label: string;
  value: string;
};

type IsMulti = false;

export const customStyles = (
  colors: Colors,
  style: CSSObject,
  menuWidth?: string,
  controlWidth?: string
): Partial<StylesConfig<OptionType, IsMulti>> => ({
  container: (base: CSSObject) => ({
    ...base,
    ...style,
  }),
  control: (base: CSSObject) => ({
    ...base,
    minHeight: 0,
    height: "30px",
    width: controlWidth || "200px",
    fontSize: "16px",
    backgroundColor: colors.background,
    borderColor: colors.selectBorderColor,
    boxShadow: "none",
  }),
  menu: (base: CSSObject) => ({
    ...base,
    backgroundColor: colors.background,
    width: menuWidth || "14em",
  }),
  indicatorsContainer: (base: CSSObject) => ({
    ...base,
    height: "25px",
  }),
  valueContainer: (base: CSSObject) => ({
    ...base,
    padding: "0 3px",
  }),
  singleValue: (base: CSSObject) => ({
    ...base,
    color: colors.textColor,
    backgroundColor: colors.background,
  }),
  option: (base: CSSObject, props) => ({
    ...base,
    padding: "4px 6px",
    color:
      props.isFocused && !props.isSelected ? colors.background : base.color,
  }),
  noOptionsMessage: (base: CSSObject) => ({
    ...base,
    padding: 0,
  }),
  indicatorSeparator: () => ({ display: "none" }),
});
