import React, { useCallback } from "react";
import { BarChart, Bar, XAxis, Cell, ResponsiveContainer } from "recharts";
import { decimalFormat } from "../../common/functions";

export type BarChartValue = {
  id: string;
  name: string;
  value: number;
  color: string;
  labelColor?: string;
};

export type Props = {
  data: BarChartValue[];
  onMouseDown?: (value: BarChartValue) => void;
};

const barSize = 30;

const renderLabel = (data: BarChartValue[], { x, y, value, index }: any) => {
  const barChartValue = data[index];
  return (
    <text
      x={x + barSize / 2}
      y={y}
      textAnchor="middle"
      dy={-8}
      style={{ fill: barChartValue.labelColor }}
      className="chart-label-value"
    >
      {decimalFormat.format(value)}
    </text>
  );
};

export const BarChartArea: React.FunctionComponent<Props> = ({
  data,
  onMouseDown,
}) => {
  const handleClick = useCallback(
    (arg: any) => onMouseDown && onMouseDown(arg),
    [onMouseDown]
  );
  return (
    <ResponsiveContainer>
      <BarChart data={data} margin={{ top: 15, left: 25, right: 25 }}>
        <XAxis dataKey="name" tickLine={false} axisLine={false} />

        <Bar
          dataKey="value"
          barSize={barSize}
          label={(props) => renderLabel(data, props)}
          radius={4}
          isAnimationActive={false}
          onMouseDown={handleClick}
          className="bar"
        >
          {data.map((entry) => (
            <Cell key={entry.id} fill={entry.color} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
