import React, { useCallback } from "react";
import { AppAction, AppState, NavigationView } from "../../../state/appState";
import { ExpandablePanel } from "../../common/ExpandablePanel/ExpandablePanel";
import { Texts, useTexts } from "../../common/hooks";
import { Menu } from "../../common/Menu";

type Props = {
  state: AppState;
  dispatch: React.Dispatch<AppAction>;
  expandablePanelRef: React.RefObject<ExpandablePanel>;
};

const getViewLabel = (view: NavigationView, texts: Texts): string => {
  switch (view.type) {
    case "Spending": {
      switch (view.value) {
        case "YearlySpending":
          return texts.yearlySpending;
        case "MonthlySpending":
          return texts.monthlySpending;
        case "AverageSpending":
          return texts.averageSpending;
        case "Transactions":
          return texts.transactions;
        case "Income":
          return texts.income;
      }
      break;
    }
    case "Assets": {
      return texts.assets;
    }
    case "Settings": {
      return texts.settings;
    }
  }
};

const navigationItems: readonly NavigationView[] = [
  { type: "Spending", value: "YearlySpending" },
  { type: "Spending", value: "MonthlySpending" },
  { type: "Spending", value: "AverageSpending" },
  { type: "Spending", value: "Transactions" },
  { type: "Spending", value: "Income" },
  { type: "Assets" },
  { type: "Settings" },
];

const viewId = (view: NavigationView): string => {
  switch (view.type) {
    case "Spending":
      return `${view.type}-${view.value}`;
    default:
      return view.type;
  }
};

export const AppMenu: React.FunctionComponent<Props> = ({
  state,
  dispatch,
  expandablePanelRef,
}) => {
  const texts = useTexts();
  const getMenuLabel = useCallback(
    (view: NavigationView): string => getViewLabel(view, texts),
    [texts]
  );

  const handleMenuItemChange = useCallback(
    (view: NavigationView) => {
      expandablePanelRef.current?.close();
      dispatch({ slice: "App", type: "NavigateToView", view: view });
    },
    [expandablePanelRef, dispatch]
  );

  return (
    <Menu
      items={navigationItems}
      getId={viewId}
      getLabel={getMenuLabel}
      selectedId={viewId(state.activeView)}
      onChange={handleMenuItemChange}
      expandablePanelRef={expandablePanelRef}
    />
  );
};
