import _ from "lodash";
import { TransactionCategory, TransactionCategoryGroup } from "./state";
import { categories_enum } from "../../__generated__/graphql-global-types";
import { Colors } from "../../components/common/hooks";

export const categoryMappings: [
  TransactionCategory,
  TransactionCategoryGroup
][] = [
  [categories_enum.Groceries, TransactionCategoryGroup.FoodAndDrink],
  [categories_enum.Bars, TransactionCategoryGroup.FoodAndDrink],
  [categories_enum.Resturants, TransactionCategoryGroup.FoodAndDrink],
  [categories_enum.Lunch, TransactionCategoryGroup.FoodAndDrink],
  [categories_enum.Bars, TransactionCategoryGroup.FoodAndDrink],
  [categories_enum.Alcohol, TransactionCategoryGroup.FoodAndDrink],
  [categories_enum.Snacks, TransactionCategoryGroup.FoodAndDrink],

  [
    categories_enum.RenovationAndRepair,
    TransactionCategoryGroup.HomeImprovements,
  ],
  [
    categories_enum.FurnitureAndInterior,
    TransactionCategoryGroup.HomeImprovements,
  ],
  [categories_enum.Garden, TransactionCategoryGroup.HomeImprovements],
  [categories_enum.Kitchen, TransactionCategoryGroup.HomeImprovements],

  [categories_enum.ClothesAndAccessories, TransactionCategoryGroup.Shopping],
  [categories_enum.BooksAndGames, TransactionCategoryGroup.Shopping],
  [categories_enum.Electronics, TransactionCategoryGroup.Shopping],
  [categories_enum.Gifts, TransactionCategoryGroup.Shopping],

  [categories_enum.Hobbies, TransactionCategoryGroup.Leisure],
  [categories_enum.SportsAndFitness, TransactionCategoryGroup.Leisure],
  [categories_enum.Events, TransactionCategoryGroup.Leisure],
  [categories_enum.Vacation, TransactionCategoryGroup.Leisure],

  [categories_enum.Pharmacy, TransactionCategoryGroup.HealthAndBeauty],
  [categories_enum.Healthcare, TransactionCategoryGroup.HealthAndBeauty],
  [categories_enum.HairDresser, TransactionCategoryGroup.HealthAndBeauty],

  [categories_enum.PublicTransport, TransactionCategoryGroup.Transport],
  [categories_enum.TransportationServices, TransactionCategoryGroup.Transport],
  [categories_enum.Parking, TransactionCategoryGroup.Transport],
  [categories_enum.Taxi, TransactionCategoryGroup.Transport],
  [categories_enum.CarAndFuel, TransactionCategoryGroup.Transport],
  [categories_enum.Bike, TransactionCategoryGroup.Transport],

  [categories_enum.Transfers, TransactionCategoryGroup.Other],
  [categories_enum.Unknown, TransactionCategoryGroup.Other],

  [categories_enum.Mortage, TransactionCategoryGroup.Home],
  [categories_enum.Electricity, TransactionCategoryGroup.Home],
  [categories_enum.Rent, TransactionCategoryGroup.Home],
  [categories_enum.Insurance, TransactionCategoryGroup.Home],
  [categories_enum.Services, TransactionCategoryGroup.Home],

  [categories_enum.ExplicitSavings, TransactionCategoryGroup.Savings],
  [categories_enum.ResidualSavings, TransactionCategoryGroup.Savings],
];

export const getCategoryGroup = (
  category: TransactionCategory
): TransactionCategoryGroup | undefined => {
  const mapping = _.find(
    categoryMappings,
    (mapping) => mapping[0] === category
  );

  return mapping?.[1];
};

const groupToCategories = _.mapValues(
  _.groupBy(categoryMappings, (mapping) => mapping[1]),
  (mappings) => mappings.map((mapping) => mapping[0])
) as {
  [key in TransactionCategoryGroup]: TransactionCategory[];
};

export const categoryColor = (
  colors: Colors,
  category: TransactionCategory
): string => {
  const categoryGroup = getCategoryGroup(category);
  const colorIndex = categoryGroup
    ? groupToCategories[categoryGroup].indexOf(category)
    : 0;

  return colors.barColorsScheme[colorIndex % colors.barColorsScheme.length];
};

export const categoryGroupColor = (
  colors: Colors,
  categoryGroup: TransactionCategoryGroup
): string => {
  const categoryGroups = Object.keys(TransactionCategoryGroup);
  const categoryGroupIndex = categoryGroups.indexOf(categoryGroup);
  const colorIndex = categoryGroupIndex !== -1 ? categoryGroupIndex : 0;

  return colors.barColorsScheme[colorIndex % colors.barColorsScheme.length];
};

export const getCategoryGroupLabel = (
  categoryGroup: TransactionCategoryGroup
): string => {
  switch (categoryGroup) {
    case TransactionCategoryGroup.HomeImprovements:
      return "Home improvements";
    case TransactionCategoryGroup.FoodAndDrink:
      return "Food and drink";
    case TransactionCategoryGroup.HealthAndBeauty:
      return "Health and beauty";
    case TransactionCategoryGroup.Leisure:
      return "Leisure";
    case TransactionCategoryGroup.Other:
      return "Other";
    case TransactionCategoryGroup.Shopping:
      return "Shopping";
    case TransactionCategoryGroup.Transport:
      return "Transport";
    case TransactionCategoryGroup.Home:
      return "Home & Utilities";
    case TransactionCategoryGroup.Savings:
      return "Savings";
  }
};

export const getCategoryLabel = (category: TransactionCategory): string => {
  switch (category) {
    case "Groceries":
      return "Groceries";
    case "Resturants":
      return "Resturants";
    case "Lunch":
      return "Lunch";
    case "Bars":
      return "Bars";
    case "Alcohol":
      return "Alcohol";
    case "Snacks":
      return "Snacks";
    case "RenovationAndRepair":
      return "Renovation and repair";
    case "FurnitureAndInterior":
      return "Furniture and interior";
    case "Garden":
      return "Garden";
    case "Kitchen":
      return "Kitchen";

    // Shopping
    case "ClothesAndAccessories":
      return "Clothes and accessories";
    case "BooksAndGames":
      return "Books and games";
    case "Electronics":
      return "Electronics";
    case "Gifts":
      return "Gifts";

    // Leisure
    case "Hobbies":
      return "Hobbies";
    case "SportsAndFitness":
      return "Sports and fitness";
    case "Events":
      return "Events";
    case "Vacation":
      return "Vacation";

    // Health & beauty
    case "Pharmacy":
      return "Pharmacy";
    case "Healthcare":
      return "Healthcare";
    case "HairDresser":
      return "Hair dresser";

    // Transport
    case "PublicTransport":
      return "Public transport";
    case "Parking":
      return "Parking";
    case "Taxi":
      return "Taxi";
    case "CarAndFuel":
      return "Car and fuel";
    case "Bike":
      return "Bike";
    case "TransportationServices":
      return "Transportation services";

    // Home
    case "Mortage":
      return "Mortage";
    case "Electricity":
      return "Electricity";
    case "Rent":
      return "Rent";
    case "Insurance":
      return "Insurance";
    case "Services":
      return "Services";

    // Savings
    case "ExplicitSavings":
      return "Explicit savings";

    case "ResidualSavings":
      return "Residual savings";

    // Other
    case "Transfers":
      return "Transfers";
    default:
      return "Unknown";
  }
};
