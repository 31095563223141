import React, { PropsWithChildren, useCallback, useMemo, useRef } from "react";
import { MonthlySpendingView } from "./MonthlySpendingView";
import { AverageSpendingView } from "./AverageSpendingView";
import { TransactionsView } from "./TransactionsView";
import { SpendingAction, SpendingState } from "../../state/spending/state";
import { SpendingNavigationView } from "../../state/appState";
import { LoadingSpinner } from "../../components/common/LoadingSpinner";
import { useKeyUp } from "../../components/common/functions";
import {
  useTransactionMaxDate,
  useTransactionMinDate,
} from "../../state/spending/hooks";
import { SpendingViewProps } from "./types";
import {
  SOURCES_QUERY,
  TRANSACTIONS_SUBSCRIPTION,
} from "../../graphql/graphql";
import { FooterButtons } from "../../components/spending/FooterButtons";
import { ExpandablePanel } from "../../components/common/ExpandablePanel/ExpandablePanel";
import { TransactionsConnectionsTable } from "../../components/spending/TransactionsConnectionsTable";
import { ApolloError, useQuery, useSubscription } from "@apollo/client";
import { TransactionsSubscription } from "../../graphql/__generated__/TransactionsSubscription";
import { toViewTransaction } from "../../state/spending/selectors";
import { IncomeView } from "./IncomeView";
import { SourcesQuery } from "../../graphql/__generated__/SourcesQuery";
import { SpendingViewHeader } from "./SpendingViewHeader";
import { YearlySpendingView } from "./YearlySpendingView";

export type Props = {
  activeView: SpendingNavigationView;
  state: SpendingState;
  dispatch: React.Dispatch<SpendingAction>;
  onError: (error: ApolloError) => JSX.Element;
  Footer: React.FC<PropsWithChildren>;
};

const getActiveView = (
  activeView: SpendingNavigationView
): React.FC<SpendingViewProps> => {
  switch (activeView) {
    case "YearlySpending": {
      return YearlySpendingView;
    }
    case "MonthlySpending": {
      return MonthlySpendingView;
    }
    case "AverageSpending": {
      return AverageSpendingView;
    }
    case "Transactions": {
      return TransactionsView;
    }
    case "Income": {
      return IncomeView;
    }
  }
};

const useTransactions = () => {
  const { data, loading, error } = useSubscription<TransactionsSubscription>(
    TRANSACTIONS_SUBSCRIPTION
  );

  const transactions = useMemo(
    () =>
      (data && data.transactions ? data.transactions : []).map(
        toViewTransaction
      ),
    [data]
  );

  return { loading, error, transactions };
};

const useSources = () => {
  const { data, loading, error } = useQuery<SourcesQuery>(SOURCES_QUERY);

  const sources = useMemo(
    () =>
      (data && data.sources ? data.sources : []).map((source) => source.source),
    [data]
  );

  return { loading, error, sources };
};

export const SpendingViewContainer: React.FunctionComponent<Props> = (
  props
) => {
  const { activeView, dispatch, onError, Footer } = props;
  const {
    transactions,
    loading: transactionsLoading,
    error: transactionsError,
  } = useTransactions();
  const {
    sources,
    loading: sourcesLoading,
    error: sourcesError,
  } = useSources();

  const transactionMinDate = useTransactionMinDate(transactions);
  const transactionMaxDate = useTransactionMaxDate(transactions);

  const handleKeyUp = React.useCallback(
    (event: KeyboardEvent) => {
      const { key } = event;
      switch (key) {
        case "ArrowLeft":
          dispatch({ type: "PreviousMonth", minDate: transactionMinDate });
          break;
        case "ArrowRight":
          dispatch({ type: "NextMonth", maxDate: transactionMaxDate });
          break;
        case "Backspace":
          dispatch({ type: "Back" });
          break;
      }
    },
    [dispatch, transactionMinDate, transactionMaxDate]
  );
  const panelRef = useRef<ExpandablePanel>(null);
  const onBankConnectionsClick = useCallback(() => {
    panelRef.current?.open();
  }, [panelRef]);

  useKeyUp(handleKeyUp);

  if (transactionsError) {
    return onError(transactionsError);
  }

  if (sourcesError) {
    return onError(sourcesError);
  }

  const ActiveView = getActiveView(activeView);
  const spendingViewProps: SpendingViewProps = {
    transactions,
    sources,
    ...props,
  };

  const view =
    transactions.length > 0 ? (
      <>
        <SpendingViewHeader {...props} />
        <ActiveView {...spendingViewProps} />
      </>
    ) : (
      <h1 style={{ textAlign: "center", marginTop: 200 }}>No data</h1>
    );

  const isLoading = transactionsLoading || sourcesLoading;

  return (
    <>
      {isLoading ? (
        <div className="loading-container">
          <LoadingSpinner />
        </div>
      ) : (
        view
      )}
      <Footer>
        <FooterButtons
          onBankConnectionsClick={onBankConnectionsClick}
          loading={isLoading}
          {...spendingViewProps}
        />
      </Footer>
      <ExpandablePanel
        expandedWidth="18em"
        expandDirection="right"
        ref={panelRef}
        className="bankconnections-panel"
        showCloseButton={false}
      >
        <TransactionsConnectionsTable />
      </ExpandablePanel>
    </>
  );
};
