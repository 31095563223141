import { asset_types_enum } from "../../__generated__/graphql-global-types";

export const getAssetTypeLabel = (assetType: asset_types_enum): string => {
  switch (assetType) {
    case asset_types_enum.CashSEK:
      return "Cash";
    case asset_types_enum.Crypto:
      return "Cryptocurrency";
    case asset_types_enum.EquityFund:
      return "Mutual funds";
    case asset_types_enum.Gold:
      return "Gold";
    case asset_types_enum.HousingCooperative:
      return "Home equity";
    case asset_types_enum.Silver:
      return "Silver";
    case asset_types_enum.Stock:
      return "Stocks";
  }
};
