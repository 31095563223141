import _ from "lodash";
import {
  ZoomLevel,
  Transaction,
  TransactionCategory,
  SpendingState,
  TransactionCategoryGroup,
} from "./state";
import {
  YearMonth,
  toYearMonth,
  equals,
  addMonths,
  comparisonValue,
  subMonths,
  parseDate,
  now,
  differenceInMonths,
} from "../../common/yearMonth";
import { getCategoryGroup } from "./transactionCategoryLabels";

export type ViewTransaction = {
  id: number;
  amount: number;
  originalAmount: number;
  description: string;
  source: string;
  payPeriod: YearMonth;
  date: Date;
  category: TransactionCategory;
  categoryGroup: TransactionCategoryGroup;
};

export const toViewTransaction = (t: Transaction): ViewTransaction => {
  const category = getTransactionCategory(t);
  const categoryGroup = getCategoryGroup(category);

  if (!categoryGroup) {
    throw new Error(`Could not find mapping for category '${category}'`);
  }

  const incomingReimbursements =
    t.transactions_reimbursements_target_aggregate.aggregate?.sum?.amount ?? 0;
  const outgoingReimbursements =
    t.transactions_reimbursements_source_aggregate.aggregate?.sum?.amount ?? 0;

  return {
    id: t.id,
    amount: t.amount + incomingReimbursements - outgoingReimbursements,
    originalAmount: t.amount,
    description: t.description,
    payPeriod: getTransactionPayPeriod(t),
    date: parseDate(t.date),
    category,
    categoryGroup,
    source: t.source,
  };
};

const getTransactionCategory = (
  transaction: Transaction
): TransactionCategory =>
  transaction.manual_category || transaction.automatic_category;

const transactionMatchesCategory = (
  transaction: ViewTransaction,
  level: ZoomLevel
): boolean =>
  level.type === "AllCategories" ||
  (level.type === "CategoryGroup" &&
    transaction.categoryGroup === level.group) ||
  (level.type === "Category" && level.category === transaction.category);

const transactionMatchesSource = (
  transaction: ViewTransaction,
  level: ZoomLevel
): boolean =>
  level.type === "AllSources" ||
  (level.type === "Source" && transaction.source === level.source);

const transactionMatchesZoomLevel = (
  transaction: ViewTransaction,
  level: ZoomLevel
): boolean =>
  transactionMatchesCategory(transaction, level) ||
  transactionMatchesSource(transaction, level);

// TODO: Find a more generic way of determining pay periods? Perhaps on the backend?
const isBankPayment = (t: Transaction) => t.source === "Swedbank";
const isSwishTransaction = (t: Transaction) =>
  t.description.toLocaleLowerCase().indexOf("swish") !== -1;

const getTransactionPayPeriod = (t: Transaction): YearMonth => {
  const category = getTransactionCategory(t);
  const yearMonth = toYearMonth(t.date);

  if (category === TransactionCategory.ResidualSavings) {
    // Previous month
    return subMonths(yearMonth, 1);
  }

  if (isBankPayment(t)) {
    if (isSwishTransaction(t)) {
      return toYearMonth(t.date);
    } else {
      const date = parseDate(t.date);
      const dayOfMonth = date.getDate();

      if (dayOfMonth <= 21) {
        return yearMonth;
      } else {
        return addMonths(yearMonth, 1);
      }
    }
  } else {
    return yearMonth;
  }
};

const transactionMatchesDate = (t: ViewTransaction, date: YearMonth): boolean =>
  equals(t.payPeriod, date);

export const transactionsMatchesZoomLevel = (
  state: SpendingState,
  transactions: readonly ViewTransaction[]
): readonly ViewTransaction[] =>
  transactions.filter((transaction: ViewTransaction) =>
    transactionMatchesZoomLevel(transaction, state.level)
  );

export const transactionsMatchingDateFilter = (
  state: SpendingState,
  transactions: readonly ViewTransaction[]
): readonly ViewTransaction[] =>
  transactions.filter((transaction: ViewTransaction) =>
    transactionMatchesDate(transaction, state.date)
  );

export const transactionsMatchingYearFilter = (
  state: SpendingState,
  transactions: readonly ViewTransaction[]
): readonly ViewTransaction[] =>
  transactions.filter(
    (transaction: ViewTransaction) =>
      transaction.payPeriod.year === state.date.year
  );

export const transactionsForAverageSpending = (
  state: SpendingState,
  transactions: readonly ViewTransaction[]
) => {
  const cutoffDate = subMonths(now(), state.averageSpendingNumMonths);
  return transactionsMatchesZoomLevel(state, transactions).filter(
    (transaction) =>
      comparisonValue(transaction.payPeriod) >= comparisonValue(cutoffDate)
  );
};

export const movingAverageSpending = (
  state: SpendingState,
  transactions: readonly ViewTransaction[]
): { date: YearMonth; value: number }[] => {
  const maxNumMonthsToDisplay = 36;
  const earliestDate = transactions[transactions.length - 1].payPeriod;
  const latestDate = transactions[0].payPeriod;
  const numMonths = differenceInMonths(latestDate, earliestDate);
  const numMonthsToDisplay = Math.min(maxNumMonthsToDisplay, numMonths);

  const nowDate = now();
  const matchingTransactions = transactionsMatchesZoomLevel(
    state,
    transactions
  );
  const dateRanges = _.range(-numMonthsToDisplay + 1, 1).map((endOffset) => {
    const startOffset = endOffset - state.averageSpendingNumMonths;
    const startDate = addMonths(nowDate, startOffset);
    const endDate = addMonths(nowDate, endOffset);

    return { start: startDate, end: endDate };
  });

  return dateRanges.map(({ start, end }) => ({
    value: _.sumBy(
      matchingTransactions.filter(
        (transaction) =>
          comparisonValue(transaction.payPeriod) >= comparisonValue(start) &&
          comparisonValue(transaction.payPeriod) <= comparisonValue(end)
      ),
      (t) => t.amount
    ),
    date: end,
  }));
};

export const getNextMonth = (state: SpendingState): YearMonth =>
  addMonths(state.date, 1);

export const getPreviousMonth = (state: SpendingState): YearMonth =>
  subMonths(state.date, 1);

export const getMinDate = (
  transactions: readonly ViewTransaction[]
): YearMonth => {
  const transaction = _.minBy(transactions, (t) =>
    comparisonValue(t.payPeriod)
  );
  return transaction?.payPeriod || toYearMonth(new Date());
};

export const getMaxDate = (
  transactions: readonly ViewTransaction[]
): YearMonth => {
  const transaction = _.maxBy(transactions, (t) =>
    comparisonValue(t.payPeriod)
  );
  return transaction?.payPeriod || toYearMonth(new Date());
};

export const hasPreviousMonth = (state: SpendingState, minDate: YearMonth) =>
  comparisonValue(getPreviousMonth(state)) >= comparisonValue(minDate);

export const hasNextMonth = (state: SpendingState, maxDate: YearMonth) =>
  comparisonValue(getNextMonth(state)) <= comparisonValue(maxDate);

export const getSelectedTransaction = (
  state: SpendingState,
  transactions: readonly ViewTransaction[]
) =>
  transactions.find(
    (transaction) => transaction.id === state.selectedTransactionId
  ) || null;
