import { Colors } from "../../components/common/hooks";

export const sourceColor = (
  colors: Colors,
  sources: readonly string[],
  source: string
): string => {
  const sourceIndex = sources.indexOf(source);
  const colorIndex = sourceIndex !== -1 ? sourceIndex : 0;
  return colors.barColorsScheme[colorIndex % colors.barColorsScheme.length];
};
