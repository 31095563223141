import { format } from "date-fns";
import React, { useCallback, useRef } from "react";
import { toJavascriptDate } from "../../common/yearMonth";
import { SpendingViewProps } from "./types";
import {
  useSelectedTransaction,
  useTransactionsMatchingDateFilter,
} from "../../state/spending/hooks";
import {
  TransactionCategory,
  TransactionCategoryGroup,
} from "../../state/spending/state";
import { ExpandablePanel } from "../../components/common/ExpandablePanel/ExpandablePanel";
import { ViewTransaction } from "../../state/spending/selectors";
import { TransactionDetails } from "../../components/spending/TransactionItemList/TransactionDetails";
import {
  currencyFormat,
  currencyFormatWithSign,
} from "../../components/common/functions";
import _ from "lodash";
import {
  categoryGroupColor,
  getCategoryGroupLabel,
  getCategoryLabel,
} from "../../state/spending/transactionCategoryLabels";
import { useColors } from "../../components/common/hooks";

import "./IncomeView.css";

type RowProps = {
  label: string;
  value: number;
  color: string;
  onMouseDown?: () => void;
};

const Row: React.FC<RowProps> = ({ label, value, color, onMouseDown }) => (
  <>
    <div>
      <div className="item-box" style={{ backgroundColor: color }}></div>
    </div>
    <div onMouseDown={onMouseDown}>{label}</div>
    <div>{currencyFormatWithSign.format(value)}</div>
  </>
);

export const IncomeView: React.FC<SpendingViewProps> = ({
  state,
  dispatch,
  transactions,
}) => {
  const panelRef = useRef<ExpandablePanel>(null);
  const colors = useColors();
  const selectedTransaction = useSelectedTransaction(state, transactions);
  const transactionsMatchingDateFilter = useTransactionsMatchingDateFilter(
    state,
    transactions
  );

  const handleClick = useCallback(
    (transaction: ViewTransaction) => {
      dispatch({ type: "SelectTransactionId", transactionId: transaction.id });
      panelRef.current?.open();
    },
    [dispatch, panelRef]
  );

  const incomeTransactions = _.chain(transactionsMatchingDateFilter)
    .filter(
      (t) =>
        t.amount > 0 &&
        t.category !== TransactionCategory.Transfers &&
        t.category !== TransactionCategory.ResidualSavings
    )
    .orderBy("amount", "desc")
    .value();

  const residualSavings = _.chain(transactionsMatchingDateFilter)
    .filter((t) => t.category === TransactionCategory.ResidualSavings)
    .sumBy("amount")
    .value();

  const expenseTransactions = _.chain(transactionsMatchingDateFilter)
    .filter((t) => t.amount < 0 && t.category !== TransactionCategory.Transfers)
    .groupBy((t) => t.categoryGroup)
    .map((transactions, group) => {
      const categoryGroup: TransactionCategoryGroup =
        TransactionCategoryGroup[
          group as keyof typeof TransactionCategoryGroup
        ];
      const sum = _.sumBy(transactions, "amount");
      const label = getCategoryGroupLabel(categoryGroup);
      const color = categoryGroupColor(colors, categoryGroup);

      return { key: group, label, sum, color };
    })
    .orderBy("sum")
    .value();

  const incomeMinusExpenses =
    _.sumBy(incomeTransactions, "amount") + _.sumBy(expenseTransactions, "sum");

  const monthFormatted = format(toJavascriptDate(state.date), "MMMM yyyy");

  return (
    <>
      <div className="income-view">
        <div className="income-month">{monthFormatted}</div>
        <div className="income-table">
          {incomeTransactions.map((t) => (
            <Row
              key={t.id}
              label={t.description}
              value={t.amount}
              color="var(--text-color)"
              onMouseDown={() => handleClick(t)}
            />
          ))}
          {_.map(expenseTransactions, ({ key, label, sum, color }) => (
            <Row key={key} label={label} value={sum} color={color} />
          ))}
          {residualSavings !== 0 ? (
            <Row
              key="residual-savings"
              label={getCategoryLabel(TransactionCategory.ResidualSavings)}
              value={residualSavings}
              color={categoryGroupColor(
                colors,
                TransactionCategoryGroup.Savings
              )}
            />
          ) : null}
          <div className="income-table-divider"></div>
          <div className="income-table-sum">
            = {currencyFormat.format(incomeMinusExpenses)}
          </div>
        </div>
      </div>
      <ExpandablePanel
        expandedWidth="18em"
        expandDirection="right"
        ref={panelRef}
        className="transactions-details-panel"
        showCloseButton={false}
      >
        {selectedTransaction ? (
          <TransactionDetails transaction={selectedTransaction} />
        ) : null}
      </ExpandablePanel>
    </>
  );
};
