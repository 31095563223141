import React, { useMemo } from "react";
import _ from "lodash";
import { parse, format } from "date-fns";
import { yearMonthToString, subMonths } from "../../../common/yearMonth";
import { getBarChartEnd, SpendingState } from "../../../state/spending/state";
import { BarChartValue } from "../../common/BarChart";
import { BarChartArea } from "../../common/BarChart/BarChartArea";
import {
  useTransactionMaxDate,
  useTransactionsByYearMonth,
} from "../../../state/spending/hooks";
import { useColors } from "../../common/hooks";
import { ViewTransaction } from "../../../state/spending/selectors";

export type Props = {
  state: SpendingState;
  transactions: readonly ViewTransaction[];
  onMouseDown: (value: BarChartValue) => void;
};

const yearMonthFormat = "yyyy-MM";
const xAxisDateFormat = "MMM";

export const TransactionMonthBarChart: React.FunctionComponent<Props> = ({
  state,
  transactions,
  onMouseDown,
}) => {
  const transactionsByYearMonth = useTransactionsByYearMonth(
    state,
    transactions
  );

  const maxDate = useTransactionMaxDate(transactions);
  const barChartEnd = getBarChartEnd(state, maxDate);

  const xAxis = useMemo(
    () =>
      _.range(0, state.numberOfBarsToDisplay)
        .map((n) => yearMonthToString(subMonths(barChartEnd, n)))
        .reverse(),
    [barChartEnd, state.numberOfBarsToDisplay]
  );
  const colors = useColors();

  const dateYearMonthString = yearMonthToString(state.date);

  const data = xAxis.map((yearMonth: string) => ({
    id: yearMonth,
    name: format(
      parse(yearMonth, yearMonthFormat, new Date()),
      xAxisDateFormat
    ),
    value: _.sumBy(transactionsByYearMonth[yearMonth], (t) => -t.amount),
    color:
      dateYearMonthString === yearMonth
        ? colors.barHighlightColor
        : colors.barBaseColor,
    labelColor:
      dateYearMonthString === yearMonth ? colors.barHighlightColor : undefined,
  }));

  return <BarChartArea data={data} onMouseDown={onMouseDown} />;
};
