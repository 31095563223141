import React from "react";

export const decimalFormat = new Intl.NumberFormat("sv-SE", {
  style: "decimal",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const currencyFormat = new Intl.NumberFormat("sv-SE", {
  style: "currency",
  currency: "SEK",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const currencyFormatWithSign = new Intl.NumberFormat("sv-SE", {
  style: "currency",
  signDisplay: "always",
  currency: "SEK",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
} as any);

export const percentFormat = new Intl.NumberFormat("sv-SE", {
  style: "percent",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const useKeyUp = (handleKeyUp: (event: KeyboardEvent) => void) => {
  React.useEffect(() => {
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  });
};
