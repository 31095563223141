import React, { useCallback } from "react";

import "./Error.css";

export const Error: React.FunctionComponent = () => {
  const handleRefreshPage = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <div className="error-container">
      <div className="error">
        <h1>Something went wrong!</h1>
        <button onMouseDown={handleRefreshPage}>Refresh page</button>
      </div>
    </div>
  );
};
