import { useMemo } from "react";
import _ from "lodash";
import {
  useTransactionMaxDate,
  useTransactionsByYear,
} from "../../../state/spending/hooks";
import { ViewTransaction } from "../../../state/spending/selectors";
import { SpendingState } from "../../../state/spending/state";
import { BarChartValue } from "../../common/BarChart";
import { useColors } from "../../common/hooks";
import { BarChartArea } from "../../common/BarChart/BarChartArea";

export type Props = {
  state: SpendingState;
  transactions: readonly ViewTransaction[];
  onMouseDown: (value: BarChartValue) => void;
};

export const TransactionYearBarChart: React.FunctionComponent<Props> = ({
  state,
  transactions,
  onMouseDown,
}) => {
  const colors = useColors();
  const maxDate = useTransactionMaxDate(transactions);
  const transactionsByYear: { [year: number]: ViewTransaction[] } =
    useTransactionsByYear(state, transactions);

  const startYear =
    maxDate.year -
    Math.floor((maxDate.year - state.date.year) / state.numberOfBarsToDisplay) *
      state.numberOfBarsToDisplay;

  const xAxis: number[] = useMemo(
    () => _.range(startYear - state.numberOfBarsToDisplay + 1, startYear + 1),
    [startYear, state.numberOfBarsToDisplay]
  );

  const data: BarChartValue[] = xAxis.map((year: number) => ({
    id: year.toString(),
    name: year.toString(),
    value: _.sumBy(transactionsByYear[year], (t) => -t.amount),
    color:
      year === state.date.year ? colors.barHighlightColor : colors.barBaseColor,
    labelColor: year === state.date.year ? colors.barHighlightColor : undefined,
  }));

  return <BarChartArea data={data} onMouseDown={onMouseDown} />;
};
