import React from "react";
import classnames from "classnames";

import "./LoadingSpinner.css";

type Props = {
  size?: "small" | "large";
};

export const LoadingSpinner: React.FC<Props> = ({ size: preferredSize }) => {
  const size = preferredSize || "large";

  const className = classnames({
    "loading-spinner-ring": true,
    large: size === "large",
    small: size === "small",
  });

  return (
    <div className={className}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
