import _ from "lodash";
import { useMemo } from "react";

export type Colors = {
  barBaseColor: string;
  lineBaseColor: string;
  barHighlightColor: string;
  barColorsScheme: string[];
  background: string;
  buttonColor: string;
  textColor: string;
  selectBorderColor: string;
};

export const useColors = (): Colors =>
  useMemo(() => {
    const style = getComputedStyle(document.documentElement);

    return {
      barBaseColor: style.getPropertyValue("--bar-base-color"),
      lineBaseColor: style.getPropertyValue("--line-base-color"),
      barHighlightColor: style.getPropertyValue("--bar-highlight-color"),
      barColorsScheme: _.range(1, 10).map((barColorNumber) =>
        style.getPropertyValue(`--bar-color-${barColorNumber}`)
      ),
      background: style.getPropertyValue("--background"),
      buttonColor: style.getPropertyValue("--button-color"),
      textColor: style.getPropertyValue("--text-color"),
      selectBorderColor: style.getPropertyValue("--select-border-color"),
    };
  }, []);

export type Texts = {
  back: string;
  previous: string;
  next: string;
  transactions: string;
  yearlySpending: string;
  monthlySpending: string;
  categories: string;
  sources: string;
  avgLastXMonths: string;
  xMonths: string;
  cash: string;
  spending: string;
  assets: string;
  averageSpending: string;
  banks: string;
  copyAsCsv: string;
  xMoreTransactions: string;
  settings: string;
  income: string;
  trendLastXMonths: string;
  unknown: string;
};

export const texts: Texts = {
  back: "Back",
  previous: "Previous",
  next: "Next",
  categories: "Categories",
  sources: "Sources",
  transactions: "Transactions",
  monthlySpending: "Monthly spending",
  yearlySpending: "Yearly spending",
  averageSpending: "Average spending",
  avgLastXMonths: "avg. last %MONTHS% months",
  xMonths: "%MONTHS% months",
  cash: "Cash",
  spending: "Spending",
  assets: "Assets",
  banks: "Banks",
  copyAsCsv: "Copy as CSV",
  xMoreTransactions: "%COUNT% more transactions...",
  settings: "Settings",
  income: "Income",
  trendLastXMonths: "Trend last %MONTHS% months",
  unknown: "Unknown",
};

export const useTexts = (): Texts => texts;
