import { PropsWithChildren, useCallback } from "react";
import { ExpandablePanel } from "../../common/ExpandablePanel/ExpandablePanel";
import { IconButton } from "../../common/IconButton";

import "./AppFooter.css";

type Props = {
  expandablePanelRef: React.RefObject<ExpandablePanel>;
};

export const AppFooter: React.FunctionComponent<PropsWithChildren<Props>> = ({
  expandablePanelRef,
  children,
}) => {
  const openMenu = useCallback(() => {
    expandablePanelRef.current?.open();
  }, [expandablePanelRef]);

  return (
    <>
      <footer>
        <div className="footer-custom-buttons">{children}</div>
        <IconButton
          style={{ marginRight: "0.5em" }}
          icon="fa-bars"
          text="Menu"
          onMouseDown={openMenu}
        />
      </footer>
    </>
  );
};
