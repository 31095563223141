import {
  InitializerArgs,
  SpendingAction,
  SpendingState,
  getInitialState as getInitialSpendingState,
} from "./spending/state";
import {
  AssetAction,
  AssetState,
  getInitialState as getInitialAssetState,
} from "./assets/state";

export type SpendingNavigationView =
  | "YearlySpending"
  | "MonthlySpending"
  | "AverageSpending"
  | "Transactions"
  | "Income";

export type NavigationView =
  | {
      type: "Spending";
      value: SpendingNavigationView;
    }
  | {
      type: "Assets";
    }
  | {
      type: "Settings";
    };

export type AppState = {
  activeView: NavigationView;
  spendingState: SpendingState;
  assetState: AssetState;
};

export type AppAction =
  | { slice: "App"; type: "NavigateToView"; view: NavigationView }
  | { slice: "Spending"; action: SpendingAction }
  | { slice: "Assets"; action: AssetAction };

export const getInitialState = (args: InitializerArgs): AppState => ({
  activeView: { type: "Spending", value: "MonthlySpending" },
  spendingState: getInitialSpendingState(args),
  assetState: getInitialAssetState(),
});
