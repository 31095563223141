import { useMutation } from "@apollo/client";
import React from "react";
import useLocalStorage from "react-use-localstorage";
import {
  START_AVANZA_SYNC,
  START_LANSFORSAKRINGAR_SYNC,
  START_NORDNET_SYNC,
  START_NORTHMILL_ASSETS_SYNC,
  START_ROCKER_SYNC,
  START_SWEDBANK_SYNC,
} from "../../graphql/graphql";
import { StartAvanzaSync } from "../../graphql/__generated__/StartAvanzaSync";
import { StartLansforsakringarSync } from "../../graphql/__generated__/StartLansforsakringarSync";
import { StartNordnetSync } from "../../graphql/__generated__/StartNordnetSync";
import { StartRockerSync } from "../../graphql/__generated__/StartRockerSync";
import {
  StartSwedbankSync,
  StartSwedbankSyncVariables,
} from "../../graphql/__generated__/StartSwedbankSync";
import { ConnectionsTable } from "../../components/bankconnections/ConnectionsTable";
import { BankSyncButton } from "../../components/bankconnections/BankSyncButton";
import { StartNorthmillAssetsSync } from "../../graphql/__generated__/StartNorthmillAssetsSync";

export const AssetsConnectionsTable: React.FC = () => {
  const [personalSecurityNumber] = useLocalStorage("personalSecurityNumber");
  const [startNordnetSync] = useMutation<StartNordnetSync>(START_NORDNET_SYNC);
  const [startAvanzaSync] = useMutation<StartAvanzaSync>(START_AVANZA_SYNC);
  const [startRockerSync] = useMutation<StartRockerSync>(START_ROCKER_SYNC);
  const [startLansforsakringarSync] = useMutation<StartLansforsakringarSync>(
    START_LANSFORSAKRINGAR_SYNC
  );
  const [startSwedbankSync] = useMutation<
    StartSwedbankSync,
    StartSwedbankSyncVariables
  >(START_SWEDBANK_SYNC, {
    variables: { personalSecurityNumber: personalSecurityNumber || null },
  });
  const [startNorthmillAssetsSync] = useMutation<StartNorthmillAssetsSync>(
    START_NORTHMILL_ASSETS_SYNC
  );

  const syncNordnet = async () => {
    const url = (await startNordnetSync()).data?.nordnet_sync.bankIdUrl!;
    window.location.href = url;
  };
  const syncAvanza = async () => {
    const url = (await startAvanzaSync()).data?.avanza_sync.bankIdUrl!;
    window.location.href = url;
  };

  const syncRocker = async () => {
    const url = (await startRockerSync()).data?.rocker_sync.bankIdUrl!;
    window.location.href = url;
  };

  const syncSwedbank = async () => {
    const url = (await startSwedbankSync()).data?.swedbank_sync.bankIdUrl!;
    window.location.href = url;
  };

  const syncLansforsakringar = async () => {
    const url = (await startLansforsakringarSync()).data?.lansforsakringar_sync
      .bankIdUrl!;
    window.location.href = url;
  };

  const syncNorthmill = async () => {
    const url = (await startNorthmillAssetsSync()).data?.northmill_assets_sync
      .bankIdUrl!;
    window.location.href = url;
  };

  return (
    <ConnectionsTable>
      <BankSyncButton
        name="Nordnet"
        imgUrl="/nordnet-144x144.png"
        imgAltText="Nordnet logo"
        onClick={syncNordnet}
      />
      <BankSyncButton
        name="Avanza"
        imgUrl="/avanza-144x144.png"
        imgAltText="Avanza logo"
        onClick={syncAvanza}
      />
      <BankSyncButton
        name="Swedbank"
        imgUrl="/swedbank-144x144.png"
        imgAltText="Swedbank logo"
        onClick={syncSwedbank}
      />
      <BankSyncButton
        name="Rocker"
        imgUrl="/rocker-144x144.png"
        imgAltText="Rocker logo"
        onClick={syncRocker}
      />
      <BankSyncButton
        name="Länsförsäkringar"
        imgUrl="/lansforsakringar-144x144.png"
        imgAltText="Länsförsäkringar logo"
        onClick={syncLansforsakringar}
      />
      <BankSyncButton
        name="Northmill"
        imgUrl="/northmill-144x144.png"
        imgAltText="Northmill logo"
        onClick={syncNorthmill}
      />
    </ConnectionsTable>
  );
};
