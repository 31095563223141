import React from "react";
import { Slice } from "../types";
import { PieChartArea } from "./PieChartArea";
import { PieChartLegend } from "./PieChartLegend";

export type Props = {
  series: readonly Slice[];
  labelBottom?: string;
  formatLegendValue: (value: number) => string;
  sliceColor: (slice: Slice, index: number) => string;
  onMouseDown?: (slice: Slice) => void;
};

export const PieChart: React.FunctionComponent<Props> = ({
  series,
  labelBottom,
  formatLegendValue,
  sliceColor,
  onMouseDown,
}) => {
  const seriesInLegend = series.filter((s) => s.showInLegend);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div style={{ flex: 1 }}>
        <PieChartArea
          series={series}
          sliceColor={sliceColor}
          onMouseDown={onMouseDown}
          labelBottom={labelBottom}
        />
      </div>
      <div style={{ flex: 1 }}>
        {seriesInLegend.length > 0 ? (
          <PieChartLegend
            series={seriesInLegend}
            onMouseDown={onMouseDown}
            sliceColor={sliceColor}
            formatValue={formatLegendValue}
          />
        ) : null}
      </div>
    </div>
  );
};
