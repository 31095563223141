import { useLazyQuery } from "@apollo/client";
import { format } from "date-fns";
import React, { useCallback } from "react";
import { useTexts } from "../../components/common/hooks";
import { IconButton } from "../../components/common/IconButton";
import { ASSETS_QUERY } from "../../graphql/graphql";
import {
  AssetsQuery,
  AssetsQueryVariables,
} from "../../graphql/__generated__/AssetsQuery";
import { Asset, AssetListing } from "../../state/assets/state";

type Props = {
  assetListing: AssetListing | null;
  style?: React.CSSProperties;
};

const copyAssetsToClipboard = (assetListing: AssetListing, assets: Asset[]) => {
  const text = assets
    .map((asset: Asset) =>
      [
        asset.account_id,
        asset.instrument_id,
        asset.name,
        Math.round(asset.market_value),
        Math.round(asset.profit),
        Math.round(asset.liability),
        Math.round(asset.market_value - asset.liability),
        asset.type,
        asset.asset_listing_id,
        format(new Date(assetListing.date), "yyyy-MM-dd"),
      ].join("\t")
    )
    .join("\n");

  navigator.clipboard.writeText(text);
};

export const CopyAsCsvButton: React.FunctionComponent<Props> = ({
  assetListing,
  style,
}) => {
  const texts = useTexts();
  const [getAssets, { loading, data }] = useLazyQuery<
    AssetsQuery,
    AssetsQueryVariables
  >(ASSETS_QUERY, {
    onCompleted: (data) => {
      copyAssetsToClipboard(assetListing!, data.assets);
    },
  });

  const onMouseDown = useCallback(() => {
    if (data) {
      copyAssetsToClipboard(assetListing!, data.assets);
    } else {
      getAssets({
        variables: { asset_listing_id: assetListing!.id },
      });
    }
  }, [data, assetListing, getAssets]);

  const isDisabled = loading || assetListing === null;

  return (
    <IconButton
      onMouseDown={onMouseDown}
      disabled={isDisabled}
      icon="fa-file-csv"
      text={texts.copyAsCsv}
      fontSize="180%"
      flash={true}
      style={style}
    />
  );
};
