import { AssetAction, AssetState } from "./state";

export const reducer = (state: AssetState, action: AssetAction): AssetState => {
  switch (action.type) {
    case "SelectAssetListingId":
      return {
        ...state,
        selectedAssetListingId: action.value,
      };
  }
};
