import { useCallback } from "react";
import classNames from "classnames";

import "./MenuButton.css";

export type MenuButtonType = "group" | "button";

export type Props<T> = {
  isActive: boolean;
  item: T;
  type: MenuButtonType;
  getLabel: (item: T) => string;
  onMouseDown?: (item: T) => void;
};

export const MenuButton = function <T>({
  isActive,
  item,
  type,
  getLabel,
  onMouseDown,
}: Props<T>) {
  const menuButtonClassName = classNames({
    "menu-button": true,
    "menu-button--active": isActive,
    "menu-button-group-button": type === "group",
  });

  const handleOnMouseDown = useCallback(
    () => onMouseDown && onMouseDown(item),
    [onMouseDown, item]
  );

  return (
    <li className={menuButtonClassName} onMouseDown={handleOnMouseDown}>
      {type === "button" ? <div className="menu-button-indicator"></div> : null}
      <div className="menu-button-content">{getLabel(item)}</div>
    </li>
  );
};
