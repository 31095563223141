import { parse } from "date-fns";

export type YearMonth = {
  year: number;
  month: number; // 1 to 12
};

export const now = (): YearMonth => {
  const date = new Date();
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
  };
};

export const parseDate = (value: any): Date => {
  if (typeof value === "string") {
    return parse(value, "yyyy-MM-dd'T'HH:mm:ssXXX", new Date());
  } else if (value instanceof Date) {
    return value;
  }
  throw new Error(`Can't convert '${value}' to Date.`);
};

export const toYearMonth = (value: any): YearMonth => {
  const date = parseDate(value);

  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
  };
};

export const toJavascriptDate = (value: YearMonth): Date =>
  new Date(value.year, value.month - 1, 1);

export const addMonths = (value: YearMonth, months: number) => {
  const totalMonths = value.year * 12 + value.month - 1;
  const newTotalMonths = totalMonths + months;

  if (newTotalMonths < 0) {
    throw new Error(
      `Resulting year after addition of ${months} months is negative, this is not supported.`
    );
  }

  const year = Math.floor(newTotalMonths / 12);
  const month = newTotalMonths % 12;

  return { year, month: month + 1 };
};

export const subMonths = (value: YearMonth, months: number) =>
  addMonths(value, -months);

export const differenceInMonths = (a: YearMonth, b: YearMonth): number =>
  a.year * 12 + a.month - (b.year * 12 + b.month);

export const yearMonthToString = (value: YearMonth): string =>
  `${value.year}-${String(value.month).padStart(2, "0")}`;

export const yearMonthFromString = (value: string): YearMonth => {
  const parts = value.split("-");
  return { year: parseInt(parts[0], 10), month: parseInt(parts[1], 10) };
};

export const equals = (a: YearMonth, b: YearMonth): boolean =>
  a.year === b.year && a.month === b.month;

export const comparisonValue = (value: YearMonth): number =>
  value.year + value.month / 12;
