import { createRoot } from "react-dom/client";
import React from "react";
import { ApolloProvider } from "@apollo/client";
import { createClient } from "./graphql/graphql";
import { App } from "./App";
import { rootElementId } from "./common/constants";

import "./index.css";

const container = document.getElementById(rootElementId);
const root = createRoot(container!);

createClient().then((client) => {
  root.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </React.StrictMode>
  );
});
