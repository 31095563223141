import React from "react";
import { ResponsiveContainer, LineChart, Line, YAxis } from "recharts";
import { decimalFormat } from "../functions";

export type LineChartValue = {
  name: string;
  value: number;
};

type Props = {
  data: LineChartValue[];
  color: string;
};

const tickFormatter = (tick: number): string => decimalFormat.format(tick);

export const LineChartArea: React.FC<Props> = ({ data, color }) => {
  return (
    <ResponsiveContainer>
      <LineChart
        data={data}
        margin={{ top: 40, left: 25, right: 30, bottom: 15 }}
      >
        <YAxis
          tickFormatter={tickFormatter}
          tickLine={false}
          axisLine={false}
          orientation="right"
          allowDecimals={false}
          tickMargin={15}
        />
        <Line
          type="natural"
          dataKey="value"
          dot={false}
          stroke={color}
          strokeWidth={2}
          isAnimationActive={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
