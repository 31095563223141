import React, { useCallback, useRef, useState } from "react";
import _ from "lodash";
import { TransactionItemGroup } from "./TransactionItemGroup";
import { ExpandablePanel } from "../../common/ExpandablePanel/ExpandablePanel";
import { TransactionDetails } from "./TransactionDetails";
import { decimalFormat } from "../../common/functions";
import { useTexts } from "../../common/hooks";
import { ViewTransaction } from "../../../state/spending/selectors";

import "./TransactionItemList.css";
import { format } from "date-fns";

const initialLimit = 50;
const limitStep = 50;

export type Props = {
  transactions: readonly ViewTransaction[];
  selectedTransaction: ViewTransaction | null;
  onMouseDown: (transaction: ViewTransaction) => void;
};

export const TransactionItemList: React.FunctionComponent<Props> = ({
  transactions,
  selectedTransaction,
  onMouseDown,
}) => {
  const texts = useTexts();
  const [limit, setLimit] = useState(initialLimit);
  const panelRef = useRef<ExpandablePanel>(null);
  const onTransactionMouseDown = useCallback(
    (transaction: ViewTransaction) => {
      onMouseDown(transaction);
      panelRef.current?.open();
    },
    [onMouseDown, panelRef]
  );

  const onShowMoreMouseDown = useCallback(() => {
    setLimit(limit + limitStep);
  }, [limit, setLimit]);

  const items = _.chain(transactions)
    .take(limit)
    .groupBy((t: ViewTransaction) => format(new Date(t.date), "yyyy-MM-dd"))
    .map((dayTransactions: ViewTransaction[], dateKey: string) => (
      <TransactionItemGroup
        key={`transactions-date-${dateKey}`}
        date={new Date(dateKey)}
        transactions={dayTransactions}
        onMouseDown={onTransactionMouseDown}
      />
    ))
    .value();

  const numAdditionalTransactions = transactions.length - limit;

  return (
    <>
      <div className="item-list">
        {items}
        {limit < transactions.length && (
          <button
            className="show-more-button"
            onMouseDown={onShowMoreMouseDown}
          >
            {texts.xMoreTransactions.replace(
              "%COUNT%",
              decimalFormat.format(numAdditionalTransactions)
            )}
          </button>
        )}
      </div>
      <ExpandablePanel
        expandedWidth="18em"
        expandDirection="right"
        ref={panelRef}
        className="transactions-details-panel"
        showCloseButton={false}
      >
        {selectedTransaction ? (
          <TransactionDetails transaction={selectedTransaction} />
        ) : null}
      </ExpandablePanel>
    </>
  );
};
