import { Asset, AssetListing } from "../../state/assets/state";
import { AssetsTable } from "./AssetsTable";
import { AssetsListingSelect } from "../../components/assets/AssetsListingSelect";
import { currencyFormat } from "../../components/common/functions";
import _ from "lodash";

type Props = {
  onChange: (assetListing: AssetListing) => void;
  selectedAssetListing: AssetListing | null;
  assetListings: AssetListing[];
  assets: Asset[];
  loading: boolean;
};

export const AssetsViewInner: React.FunctionComponent<Props> = ({
  onChange,
  selectedAssetListing,
  assetListings,
  assets,
  loading,
}) => {
  if (assetListings.length === 0) {
    return <h1 style={{ textAlign: "center", marginTop: 200 }}>No data</h1>;
  }

  // Should never be able to happen if list of assetListings is not empty
  if (selectedAssetListing === null) {
    throw new Error(`selectedAssetListing has no value!`);
  }

  const sum = _.sumBy(assets, (asset) => asset.market_value - asset.liability);

  return (
    <>
      <div className="assets-view-select-container">
        <AssetsListingSelect
          onChange={onChange}
          items={assetListings}
          value={selectedAssetListing}
          controlWidth="9em"
          menuWidth="10em"
        />
        <div className="assets-total-value">
          {loading ? "" : currencyFormat.format(sum)}
        </div>
      </div>
      <AssetsTable assets={assets} loading={loading} />
    </>
  );
};
