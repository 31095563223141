import {
  ApolloClient,
  gql,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";

export const TRANSACTIONS_SUBSCRIPTION = gql`
  subscription TransactionsSubscription {
    transactions(order_by: [{ date: desc }, { id: desc }]) {
      id
      amount
      automatic_category
      manual_category
      date
      description
      source
      transactions_reimbursements_source_aggregate {
        aggregate {
          sum {
            amount
          }
        }
      }
      transactions_reimbursements_target_aggregate {
        aggregate {
          sum {
            amount
          }
        }
      }
    }
  }
`;

export const SOURCES_QUERY = gql`
  query SourcesQuery {
    sources: transactions(distinct_on: source) {
      source
    }
  }
`;

export const UPDATE_MANUAL_CATEGORY = gql`
  mutation UpdateManualCategory($id: Int!, $category: categories_enum) {
    update_transactions(
      where: { id: { _eq: $id } }
      _set: { manual_category: $category }
    ) {
      returning {
        id
      }
    }
  }
`;

export const START_NORDNET_SYNC = gql`
  mutation StartNordnetSync {
    nordnet_sync {
      bankIdUrl
    }
  }
`;

export const START_AVANZA_SYNC = gql`
  mutation StartAvanzaSync {
    avanza_sync {
      bankIdUrl
    }
  }
`;

export const START_SWEDBANK_SYNC = gql`
  mutation StartSwedbankSync($personalSecurityNumber: String) {
    swedbank_sync(personalSecurityNumber: $personalSecurityNumber) {
      bankIdUrl
    }
  }
`;

export const START_LANSFORSAKRINGAR_SYNC = gql`
  mutation StartLansforsakringarSync {
    lansforsakringar_sync {
      bankIdUrl
    }
  }
`;

export const START_SWEDBANK_TRANSACTIONS_SYNC = gql`
  mutation StartSwedbankTransactionsSync($personalSecurityNumber: String) {
    swedbank_transactions_sync(
      personalSecurityNumber: $personalSecurityNumber
    ) {
      bankIdUrl
    }
  }
`;

export const START_ROCKER_SYNC = gql`
  mutation StartRockerSync {
    rocker_sync {
      bankIdUrl
    }
  }
`;

export const START_ROCKER_TRANSACTIONS_SYNC = gql`
  mutation StartRockerTransactionsSync {
    rocker_transactions_sync {
      bankIdUrl
    }
  }
`;

export const LUNAR_ASSETS_SYNC = gql`
  mutation LunarAssetsSync($base64csv: String!) {
    lunar_assets_sync(base64csv: $base64csv) {
      success
    }
  }
`;

export const LUNAR_TRANSACTIONS_SYNC = gql`
  mutation LunarTransactionsSync($base64csv: String!) {
    lunar_transactions_sync(base64csv: $base64csv) {
      success
    }
  }
`;

export const START_NORTHMILL_ASSETS_SYNC = gql`
  mutation StartNorthmillAssetsSync {
    northmill_assets_sync {
      bankIdUrl
    }
  }
`;

export const START_NORTHMILL_TRANSACTIONS_SYNC = gql`
  mutation StartNorthmillTransactionsSync {
    northmill_transactions_sync {
      bankIdUrl
    }
  }
`;

export const ASSET_LISTINGS_SUBSCRIPTION = gql`
  subscription AssetListingsSubscription {
    asset_listings {
      date
      id
    }
  }
`;

export const ASSETS_FRAGMENT = gql`
  fragment AssetsFields on assets {
    id
    account_id
    asset_listing_id
    instrument_id
    name
    liability
    market_value
    profit
    source
    type
  }
`;

export const ASSETS_SUBSCRIPTION = gql`
  ${ASSETS_FRAGMENT}
  subscription AssetsSubscription($asset_listing_id: Int!) {
    assets(where: { asset_listing_id: { _eq: $asset_listing_id } }) {
      ...AssetsFields
    }
  }
`;

export const ASSETS_QUERY = gql`
  ${ASSETS_FRAGMENT}
  query AssetsQuery($asset_listing_id: Int!) {
    assets(where: { asset_listing_id: { _eq: $asset_listing_id } }) {
      ...AssetsFields
    }
  }
`;

const splitLink = () => {
  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_HTTP_API_URL!,
  });

  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_WEBSOCKET_API_URL!,
    options: {
      reconnect: true,
    },
  });

  return split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );
};

const MAX_AUTHENTICATION_ATTEMPTS = 5;
const isDevelopment =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";
const loginUrl = process.env.REACT_APP_VOUCH_LOGIN_URL!;
const validateUrl = process.env.REACT_APP_VOUCH_VALIDATE_URL!;

export const pollAuthentication = async (): Promise<void> => {
  console.group("pollAuthentication");
  console.log("isDevelopment", isDevelopment);
  console.log("validateUrl", validateUrl);
  console.log("loginUrl", loginUrl);
  console.groupEnd();

  if (isDevelopment) {
    return;
  }

  const attemps = parseInt(
    sessionStorage.vouchAuthenticationAttempts || "0",
    10
  );

  if (attemps > MAX_AUTHENTICATION_ATTEMPTS) {
    return;
  }

  sessionStorage.vouchAuthenticationAttempts = attemps + 1;

  const validateResponse = await fetch(validateUrl, { credentials: "include" });
  const isAuthenticated =
    validateResponse.status >= 200 && validateResponse.status <= 299;

  if (isAuthenticated) {
    delete sessionStorage.vouchAuthenticationAttempts;
    return;
  }

  window.location.href = loginUrl;
};

export const createClient = async () => {
  await pollAuthentication();

  return new ApolloClient({
    link: splitLink(),
    cache: new InMemoryCache(),
  });
};
