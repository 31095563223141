export const toBase64 = async (file: File): Promise<string> => {
  const text = await file.text();
  const blob = new Blob([text], { type: "text/plain;charset=utf-8" });

  return new Promise(async (resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const dataUrl = reader.result?.toString() || "";
      const base64 = dataUrl.slice(dataUrl.indexOf(",") + 1);
      resolve(base64);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob);
  });
};
