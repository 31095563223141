import React from "react";
import { ExpandablePanel } from "../ExpandablePanel/ExpandablePanel";
import { MenuButton } from "./MenuButton";
import { genericMemo } from "../../../common/genericMemo";

import "./Menu.css";

export type Props<T> = {
  selectedId: string | number;
  items: readonly T[];
  getLabel: (item: T) => string;
  getId: (item: T) => string | number;
  onChange: (item: T) => void;
  expandablePanelRef: React.RefObject<ExpandablePanel>;
};

const MenuComponent = <T,>({
  selectedId,
  items,
  getLabel,
  getId,
  onChange,
  expandablePanelRef,
}: Props<T>) => (
  <>
    <ExpandablePanel
      expandedWidth="18em"
      expandDirection="right"
      showCloseButton={false}
      className="menu-panel"
      ref={expandablePanelRef}
    >
      <div className="menu-content">
        <ul>
          {items.map((item) => (
            <MenuButton
              key={`menu-item-${getId(item)}`}
              isActive={selectedId === getId(item)}
              item={item}
              getLabel={getLabel}
              onMouseDown={onChange}
              type="button"
            />
          ))}
        </ul>
      </div>
    </ExpandablePanel>
  </>
);

export const Menu = genericMemo(MenuComponent);
