import { categories_enum as TransactionCategory } from "../../__generated__/graphql-global-types";
import {
  toYearMonth,
  YearMonth,
  subMonths,
  differenceInMonths,
} from "../../common/yearMonth";
import { TransactionsSubscription_transactions } from "../../graphql/__generated__/TransactionsSubscription";

export type Transaction = TransactionsSubscription_transactions;
export { categories_enum as TransactionCategory } from "../../__generated__/graphql-global-types";

export enum TransactionCategoryGroup {
  FoodAndDrink = "FoodAndDrink",
  HomeImprovements = "HomeImprovements",
  Shopping = "Shopping",
  Leisure = "Leisure",
  HealthAndBeauty = "HealthAndBeauty",
  Transport = "Transport",
  Home = "Home",
  Other = "Other",
  Savings = "Savings",
}

export type ZoomLevel =
  | { type: "AllCategories" }
  | { type: "AllSources" }
  | { type: "Source"; source: string }
  | { type: "CategoryGroup"; group: TransactionCategoryGroup }
  | { type: "Category"; category: TransactionCategory };

export type SpendingState = {
  level: ZoomLevel;
  numberOfBarsToDisplay: number;
  date: YearMonth;
  averageSpendingNumMonths: number;
  selectedTransactionId: number | null;
};

export type SpendingAction =
  | {
      type: "SelectSource";
      source: string;
    }
  | {
      type: "SelectGroup";
      group: TransactionCategoryGroup;
    }
  | {
      type: "SelectCategory";
      category: TransactionCategory;
    }
  | { type: "SelectAllCategories" }
  | { type: "SelectAllSources" }
  | { type: "Back" }
  | { type: "PreviousMonth"; minDate: YearMonth }
  | { type: "NextMonth"; maxDate: YearMonth }
  | { type: "PreviousYear"; minDate: YearMonth }
  | { type: "NextYear"; maxDate: YearMonth }
  | { type: "SetDate"; date: YearMonth }
  | { type: "SelectTransactionId"; transactionId: number }
  | { type: "SetAverageSpendingNumMonths"; months: number };

export type InitializerArgs = {
  numberOfBarsToDisplay: number;
};

export const getBarChartEnd = (state: SpendingState, maxDate: YearMonth) =>
  subMonths(
    maxDate,
    Math.floor(
      differenceInMonths(maxDate, state.date) / state.numberOfBarsToDisplay
    ) * state.numberOfBarsToDisplay
  );

export const getInitialState = ({
  numberOfBarsToDisplay,
}: InitializerArgs): SpendingState => ({
  level: { type: "AllCategories" },
  date: toYearMonth(new Date()),
  numberOfBarsToDisplay,
  averageSpendingNumMonths: 6,
  selectedTransactionId: null,
});
