import React from "react";
import Select from "react-select";
import { CSSObject } from "@emotion/serialize";
import { format } from "date-fns";
import { OptionType, customStyles } from "../common/reactSelect";
import { AssetListing } from "../../state/assets/state";
import { useColors } from "../common/hooks";

export type Props = {
  value: AssetListing;
  items: readonly AssetListing[];
  onChange: (newValue: AssetListing) => void;
  style?: Partial<CSSObject>;
  menuWidth?: string;
  controlWidth?: string;
};

const getAssetListingLabel = (assetListing: AssetListing): string =>
  format(new Date(assetListing.date), "d MMM, yyyy");

export const AssetsListingSelect: React.FunctionComponent<Props> = ({
  value,
  items,
  onChange,
  style,
  menuWidth,
  controlWidth,
}) => {
  const colors = useColors();
  const options: OptionType[] = items.map((item: AssetListing) => ({
    value: item.id.toString(),
    label: getAssetListingLabel(item),
  }));

  const selectedValue: OptionType = {
    value: value.id.toString(),
    label: getAssetListingLabel(value),
  };

  const handleChange = (option: OptionType | null) => {
    if (option !== null) {
      const assetListing = items.find(
        (item) => item.id.toString() === option.value
      );
      onChange(assetListing!);
    }
  };

  return (
    <Select
      styles={customStyles(colors, style || {}, menuWidth, controlWidth)}
      value={selectedValue}
      options={options}
      onChange={handleChange}
      isSearchable={false}
    />
  );
};
