import React, { useCallback } from "react";
import { hasNextMonth, hasPreviousMonth } from "../../state/spending/selectors";
import { SpendingViewProps } from "../../views/spending/types";
import {
  useTransactionMaxDate,
  useTransactionMinDate,
} from "../../state/spending/hooks";
import { IconButton } from "../common/IconButton";
import { useTexts } from "../common/hooks";

type Props = {
  onBankConnectionsClick: () => void;
  loading: boolean;
} & SpendingViewProps;

export const FooterButtons: React.FunctionComponent<Props> = ({
  activeView,
  transactions,
  state,
  dispatch,
  loading,
  onBankConnectionsClick,
}) => {
  const texts = useTexts();
  const transactionMinDate = useTransactionMinDate(transactions);
  const transactionMaxDate = useTransactionMaxDate(transactions);
  const handleBackClick = useCallback(
    () => dispatch({ type: "Back" }),
    [dispatch]
  );
  const handlePreviousClick = useCallback(
    () =>
      dispatch({
        type:
          activeView === "YearlySpending" ? "PreviousYear" : "PreviousMonth",
        minDate: transactionMinDate,
      }),
    [dispatch, transactionMinDate, activeView]
  );
  const handleNextClick = useCallback(
    () =>
      dispatch({
        type: activeView === "YearlySpending" ? "NextYear" : "NextMonth",
        maxDate: transactionMaxDate,
      }),
    [dispatch, transactionMaxDate, activeView]
  );

  const backButtonDisabled =
    state.level.type === "AllCategories" || state.level.type === "AllSources";
  const previousButtonDisabled = !hasPreviousMonth(state, transactionMinDate);
  const nextButtonDisabled = !hasNextMonth(state, transactionMaxDate);
  const previousNextButtonsVisible =
    !loading &&
    (activeView === "MonthlySpending" ||
      activeView === "YearlySpending" ||
      activeView === "Income");
  const backButtonVisible =
    !loading &&
    (activeView === "AverageSpending" ||
      activeView === "MonthlySpending" ||
      activeView === "YearlySpending");
  const banksButtonVisible = !loading;

  return (
    <>
      {activeView === "Transactions" ? (
        <IconButton
          onMouseDown={onBankConnectionsClick}
          icon="fa-university"
          text={texts.banks}
          style={{
            visibility: banksButtonVisible ? "visible" : "hidden",
          }}
        />
      ) : (
        <>
          <IconButton
            icon="fa-level-down-alt"
            text={texts.back}
            disabled={backButtonDisabled}
            onMouseDown={handleBackClick}
            style={{
              visibility: backButtonVisible ? "visible" : "hidden",
            }}
            rotation={90}
          />
          <IconButton
            icon="fa-angle-left"
            text={texts.previous}
            disabled={previousButtonDisabled}
            onMouseDown={handlePreviousClick}
            style={{
              visibility: previousNextButtonsVisible ? "visible" : "hidden",
            }}
          />
          <IconButton
            icon="fa-angle-right"
            text={texts.next}
            disabled={nextButtonDisabled}
            onMouseDown={handleNextClick}
            style={{
              visibility: previousNextButtonsVisible ? "visible" : "hidden",
            }}
          />
        </>
      )}
    </>
  );
};
