import { useMutation } from "@apollo/client";
import React, { ChangeEvent, useState } from "react";
import ReactDOM from "react-dom";
import { rootElementId } from "../../common/constants";
import { toBase64 } from "../../common/file";
import { LUNAR_TRANSACTIONS_SYNC } from "../../graphql/graphql";
import { LunarAssetsSyncVariables } from "../../graphql/__generated__/LunarAssetsSync";
import { LunarTransactionsSync } from "../../graphql/__generated__/LunarTransactionsSync";
import { AsyncOperationButton } from "../common/AsyncOperationButton/AsyncOperationButton";

import "./LunarCsvUploadDialog.css";

type Props = {
  onClose: () => void;
  onUpload?: () => Promise<void>;
};
const stopPropagation = (e: React.UIEvent) => e.stopPropagation();

export const LunarCsvUploadDialog: React.FC<Props> = ({ onClose }) => {
  const [file, setFile] = useState<File>();
  const root = document.getElementById(rootElementId);

  const [lunarSyncTransactions] = useMutation<
    LunarTransactionsSync,
    LunarAssetsSyncVariables
  >(LUNAR_TRANSACTIONS_SYNC);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const click = async (e: React.UIEvent): Promise<void> => {
    stopPropagation(e);

    if (file) {
      await lunarSyncTransactions({
        variables: { base64csv: await toBase64(file) },
      });
      onClose();
    }
  };

  const canUpload = !!file;

  return ReactDOM.createPortal(
    <div
      className="upload-dialog-container"
      onMouseDown={onClose}
      onTouchStart={onClose}
    >
      <div
        className="upload-dialog"
        onMouseDown={stopPropagation}
        onTouchStart={stopPropagation}
      >
        <div className="upload-dialog-header">
          <h2>Lunar import</h2>
        </div>
        <div className="upload-dialog-content">
          Open the Lunar app, click on your account, click on <em>Statement</em>
          , select File format <em>CSV</em> and click <em>Get statement</em>.
          <br /> <br />
          Save the file to your device, then go back here and upload it.
          <br /> <br />
          <input type="file" onChange={handleFileChange} />
        </div>
        <div className="upload-dialog-footer">
          <div></div>
          <button onMouseDown={onClose} onTouchStart={onClose}>
            Cancel
          </button>
          <AsyncOperationButton
            text="Upload"
            onClick={canUpload ? click : undefined}
            disabled={!canUpload}
          />
        </div>
      </div>
    </div>,
    root!
  );
};
