import _ from "lodash";
import { Dispatch } from "react";
import { ViewTransaction } from "../../state/spending/selectors";
import { sourceColor } from "../../state/spending/sourceColors";
import {
  SpendingAction,
  SpendingState,
  TransactionCategory,
  TransactionCategoryGroup,
  ZoomLevel,
} from "../../state/spending/state";
import {
  categoryColor,
  categoryGroupColor,
  getCategoryGroupLabel,
  getCategoryLabel,
} from "../../state/spending/transactionCategoryLabels";
import { Colors } from "../common/hooks";
import { Slice } from "../common/types";

export const onPieClick = (
  state: SpendingState,
  slice: Slice,
  dispatch: Dispatch<SpendingAction>
): void => {
  switch (state.level.type) {
    case "AllSources": {
      dispatch({
        type: "SelectSource",
        source: slice.id,
      });
      break;
    }
    case "AllCategories": {
      dispatch({
        type: "SelectGroup",
        group: slice.id as TransactionCategoryGroup,
      });
      break;
    }
    case "CategoryGroup": {
      dispatch({
        type: "SelectCategory",
        category: slice.id as TransactionCategory,
      });
      break;
    }
  }
};

export const getPieSeries = (
  transactions: readonly ViewTransaction[],
  grouping: (t: ViewTransaction) => string,
  getLabel: (grouping: string) => string
): readonly Slice[] =>
  _.chain(transactions)
    .groupBy(grouping)
    .mapValues((transactions) => _.sumBy(transactions, (t) => -t.amount))
    .toPairs()
    .map(([category, amount]) => ({
      id: category,
      name: getLabel(category),
      value: amount!,
      showInLegend: true,
    }))
    .sortBy(({ value }) => value)
    .value();

export const pieGroupingFunction = (
  level: ZoomLevel,
  t: ViewTransaction
): string => {
  switch (level.type) {
    case "AllCategories": {
      return t.categoryGroup.toString();
    }
    case "Category":
    case "CategoryGroup":
      return t.category;
    case "AllSources":
    case "Source":
      return t.source;
  }
};

export const pieLabelFunction = (
  level: ZoomLevel,
  category: string
): string => {
  switch (level.type) {
    case "AllCategories":
      return getCategoryGroupLabel(category as TransactionCategoryGroup);
    case "Category":
    case "CategoryGroup":
      return getCategoryLabel(category as TransactionCategory);
    case "AllSources":
    case "Source":
      return category;
  }
};

export const pieSliceColor = (
  colors: Colors,
  level: ZoomLevel,
  sources: readonly string[],
  slice: Slice
): string => {
  switch (level.type) {
    case "AllCategories":
      return categoryGroupColor(colors, slice.id as TransactionCategoryGroup);
    case "Category":
    case "CategoryGroup":
      return categoryColor(colors, slice.id as TransactionCategory);
    case "AllSources":
    case "Source":
      return sourceColor(colors, sources, slice.id);
  }
};
