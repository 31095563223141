import React from "react";
import { TransactionItem } from "./TransactionItem";
import { format } from "date-fns";
import { ViewTransaction } from "../../../state/spending/selectors";

import "./TransactionItemGroup.css";

type Props = {
  date: Date;
  transactions: readonly ViewTransaction[];
  onMouseDown?: (transaction: ViewTransaction) => void;
};

export const TransactionItemGroup: React.FunctionComponent<Props> = ({
  date,
  transactions,
  onMouseDown,
}) => {
  const dateFormatted = format(date, "EEEE, d MMMM");
  return (
    <>
      <div className="item-group">{dateFormatted}</div>
      {transactions.map((transaction) => {
        return (
          <TransactionItem
            key={transaction.id}
            transaction={transaction}
            onMouseDown={onMouseDown}
          />
        );
      })}
    </>
  );
};
