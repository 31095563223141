import React, { useCallback } from "react";
import useLocalStorage from "react-use-localstorage";
import { useTexts } from "../components/common/hooks";

import "./SettingsView.css";

type Props = {
  Footer: React.FunctionComponent;
};

export const SettingsView: React.FC<Props> = ({ Footer }) => {
  const texts = useTexts();
  const [personalSecurityNumber, setPersonalSecurityNumber] = useLocalStorage(
    "personalSecurityNumber"
  );

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPersonalSecurityNumber(event.target.value);
    },
    [setPersonalSecurityNumber]
  );

  const onHardRefresh = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <>
      <header>
        <h1>{texts.settings}</h1>
      </header>
      <div className="settings-view">
        <div className="input-group">
          <span>Personal security number</span>
          <span>(for bank sync)</span>
          <input
            type="text"
            value={personalSecurityNumber}
            onChange={onInputChange}
          />
        </div>
        <button onMouseDown={onHardRefresh}>Hard refresh</button>
      </div>
      <Footer />
    </>
  );
};
