import _ from "lodash";
import { useMemo } from "react";
import { yearMonthToString } from "../../common/yearMonth";
import {
  getMaxDate,
  getMinDate,
  getSelectedTransaction,
  movingAverageSpending,
  transactionsForAverageSpending,
  transactionsMatchesZoomLevel,
  transactionsMatchingDateFilter,
  transactionsMatchingYearFilter,
  ViewTransaction,
} from "./selectors";
import { SpendingState } from "./state";

export const useTransactionsByYearMonth = (
  state: SpendingState,
  transactions: readonly ViewTransaction[]
) =>
  useMemo(
    () =>
      _.chain(transactionsMatchesZoomLevel(state, transactions))
        .groupBy((t: ViewTransaction) => yearMonthToString(t.payPeriod))
        .value(),
    [state, transactions]
  );

export const useTransactionsByYear = (
  state: SpendingState,
  transactions: readonly ViewTransaction[]
) =>
  useMemo(
    () =>
      _.chain(transactionsMatchesZoomLevel(state, transactions))
        .groupBy((t: ViewTransaction) => t.payPeriod.year)
        .value(),
    [state, transactions]
  );

export const useTransactionsMatchingDateFilter = (
  state: SpendingState,
  transactions: readonly ViewTransaction[]
) =>
  useMemo(
    () => transactionsMatchingDateFilter(state, transactions),
    [state, transactions]
  );

export const useTransactionsMatchingYearFilterAndZoomLevel = (
  state: SpendingState,
  transactions: readonly ViewTransaction[]
) =>
  useMemo(
    () =>
      _.flow(
        (_transactions) => transactionsMatchesZoomLevel(state, _transactions),
        (_transactions) => transactionsMatchingYearFilter(state, _transactions)
      )(transactions),
    [state, transactions]
  );

export const useTransactionsMatchingDateFilterAndZoomLevel = (
  state: SpendingState,
  transactions: readonly ViewTransaction[]
) =>
  useMemo(
    () =>
      _.flow(
        (_transactions) => transactionsMatchesZoomLevel(state, _transactions),
        (_transactions) => transactionsMatchingDateFilter(state, _transactions)
      )(transactions),
    [state, transactions]
  );

export const useSelectedTransaction = (
  state: SpendingState,
  transactions: readonly ViewTransaction[]
) =>
  useMemo(
    () => getSelectedTransaction(state, transactions),
    [state, transactions]
  );

export const useTransactionMinDate = (
  transactions: readonly ViewTransaction[]
) => useMemo(() => getMinDate(transactions), [transactions]);

export const useTransactionMaxDate = (
  transactions: readonly ViewTransaction[]
) => useMemo(() => getMaxDate(transactions), [transactions]);

export const useTransactionsForAverageSpendingSelector = (
  state: SpendingState,
  transactions: readonly ViewTransaction[]
) =>
  useMemo(
    () => transactionsForAverageSpending(state, transactions),
    [state, transactions]
  );

export const useMovingAverageSpendingSelector = (
  state: SpendingState,
  transactions: readonly ViewTransaction[]
) =>
  useMemo(
    () => movingAverageSpending(state, transactions),
    [state, transactions]
  );
