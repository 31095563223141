import { useCallback } from "react";
import {
  getPieSeries,
  onPieClick,
  pieGroupingFunction,
  pieLabelFunction,
  pieSliceColor,
} from "../../components/spending/transactionPieChartFunctions";
import { Slice } from "../../components/common/types";
import { PieChart } from "../../components/common/PieChart";
import {
  TransactionCategory,
  TransactionCategoryGroup,
} from "../../state/spending/state";
import { TransactionItemList } from "../../components/spending/TransactionItemList";
import { currencyFormat } from "../../components/common/functions";
import { SpendingViewProps } from "./types";
import {
  useMovingAverageSpendingSelector,
  useSelectedTransaction,
  useTransactionsForAverageSpendingSelector,
} from "../../state/spending/hooks";
import { useColors, useTexts } from "../../components/common/hooks";
import { ViewTransaction } from "../../state/spending/selectors";
import {
  LineChartArea,
  LineChartValue,
} from "../../components/common/LineChart/LineChartArea";
import { toJavascriptDate, YearMonth } from "../../common/yearMonth";
import { format } from "date-fns";

const formatLegendValue = (value: number) => `${currencyFormat.format(value)}`;

const xAxisDateFormat = "MMM";

const getMovingAverageLineSeries = (
  averageNumMonths: number,
  values: { date: YearMonth; value: number }[]
): LineChartValue[] =>
  values.map(({ date, value }) => ({
    name: format(toJavascriptDate(date), xAxisDateFormat),
    value: -value / averageNumMonths,
  }));

export const AverageSpendingView: React.FunctionComponent<SpendingViewProps> = (
  props
) => {
  const texts = useTexts();
  const { state, dispatch, transactions, sources } = props;
  const filteredTransactions = transactions.filter((t) => {
    return (
      t.amount < 0 &&
      t.category !== TransactionCategory.Transfers &&
      t.categoryGroup !== TransactionCategoryGroup.Savings
    );
  });
  const labelBottomText = texts.avgLastXMonths.replace(
    "%MONTHS%",
    state.averageSpendingNumMonths.toString()
  );

  const transactionsForAverageSpending =
    useTransactionsForAverageSpendingSelector(state, filteredTransactions);
  const selectedTransaction = useSelectedTransaction(
    state,
    filteredTransactions
  );

  const movingAverageSpendingValues = useMovingAverageSpendingSelector(
    state,
    filteredTransactions
  );

  const groupingFunction = useCallback(
    (t: ViewTransaction): string => pieGroupingFunction(state.level, t),
    [state]
  );

  const labelFunction = useCallback(
    (category: string): string => pieLabelFunction(state.level, category),
    [state]
  );

  const colors = useColors();

  const sliceColor = useCallback(
    (slice: Slice): string =>
      pieSliceColor(colors, state.level, sources, slice),
    [state, colors, sources]
  );

  const handlePieClick = useCallback(
    (slice: Slice): void => onPieClick(state, slice, dispatch),
    [state, dispatch]
  );

  const handleTransactionMouseDown = useCallback(
    (transaction: ViewTransaction) => {
      dispatch({ type: "SelectTransactionId", transactionId: transaction.id });
    },
    [dispatch]
  );

  const pieSeries = getPieSeries(
    transactionsForAverageSpending,
    groupingFunction,
    labelFunction
  ).map((slice: Slice) => ({
    ...slice,
    value: slice.value / state.averageSpendingNumMonths,
  }));

  const lineSeries = getMovingAverageLineSeries(
    state.averageSpendingNumMonths,
    movingAverageSpendingValues
  );

  const placeholderPieSeries: Slice = {
    id: "placeholder",
    name: "",
    value: 0.0001,
    showInLegend: false,
  };

  return (
    <>
      {state.level.type === "Category" ? (
        <TransactionItemList
          transactions={transactionsForAverageSpending}
          onMouseDown={handleTransactionMouseDown}
          selectedTransaction={selectedTransaction}
        />
      ) : (
        <div className="chart-container">
          <div style={{ flex: 13 }}>
            <PieChart
              series={pieSeries.length > 0 ? pieSeries : [placeholderPieSeries]}
              sliceColor={sliceColor}
              formatLegendValue={formatLegendValue}
              onMouseDown={handlePieClick}
              labelBottom={labelBottomText}
            />
          </div>
          <div style={{ flex: 6 }}>
            <LineChartArea color={colors.lineBaseColor} data={lineSeries} />
          </div>
          <div style={{ flex: 1 }}>
            {texts.trendLastXMonths.replace(
              "%MONTHS%",
              lineSeries.length.toString()
            )}
          </div>
        </div>
      )}
    </>
  );
};
