import React, { useCallback } from "react";
import {
  categoryGroupColor,
  getCategoryLabel,
} from "../../../state/spending/transactionCategoryLabels";
import { currencyFormatWithSign } from "../../common/functions";
import { ViewTransaction } from "../../../state/spending/selectors";
import { categories_enum } from "../../../__generated__/graphql-global-types";
import { useColors } from "../../common/hooks";

import "./TransactionItem.css";
import classNames from "classnames";

type Props = {
  transaction: ViewTransaction;
  onMouseDown?: (transaction: ViewTransaction) => void;
};

export const TransactionItem: React.FunctionComponent<Props> = ({
  transaction,
  onMouseDown,
}) => {
  const handleOnMouseDown = useCallback(() => {
    if (onMouseDown) {
      onMouseDown(transaction);
    }
  }, [onMouseDown, transaction]);
  const colors = useColors();
  const color = categoryGroupColor(colors, transaction.categoryGroup);
  const hasModifiedAmount = transaction.amount !== transaction.originalAmount;
  const amountClassName = classNames({
    strikethrough: hasModifiedAmount,
  });

  return (
    <div className="item" onMouseDown={handleOnMouseDown}>
      <div className="item-box" style={{ backgroundColor: color }}></div>
      <div className="item-description">
        <div className="item-title" title={transaction.description}>
          {transaction.description}
        </div>
        <div className="item-subtitle">
          {getCategoryLabel(categories_enum[transaction.category])}
        </div>
        <div className="item-value">
          <span className={amountClassName}>
            {currencyFormatWithSign.format(transaction.originalAmount)}
          </span>
          {hasModifiedAmount ? (
            <>
              &nbsp;
              {currencyFormatWithSign.format(transaction.amount)}
            </>
          ) : null}
        </div>
      </div>
      <div className="item-right">
        <div
          className="chevron right"
          style={{ color: colors.buttonColor }}
        ></div>
      </div>
    </div>
  );
};
