import React, { Dispatch, PropsWithChildren, useCallback, useRef } from "react";
import { LoadingSpinner } from "../../components/common/LoadingSpinner";
import {
  AssetAction,
  AssetListing,
  AssetState,
} from "../../state/assets/state";
import { AssetsViewInner } from "./AssetsViewInner";
import { ExpandablePanel } from "../../components/common/ExpandablePanel/ExpandablePanel";
import { IconButton } from "../../components/common/IconButton";
import { CopyAsCsvButton } from "./CopyAsCsvButton";
import { useAssetListingsSubscription, useAssetsSubscription } from "./hooks";
import { AssetsConnectionsTable } from "./AssetsConnectionsTable";
import { useTexts } from "../../components/common/hooks";

import "./AssetsView.css";
import { ApolloError } from "@apollo/client";

type Props = {
  state: AssetState;
  dispatch: Dispatch<AssetAction>;
  onError: (error: ApolloError) => JSX.Element;
  Footer: React.FunctionComponent<PropsWithChildren>;
};

export const AssetsView: React.FunctionComponent<Props> = ({
  state,
  dispatch,
  onError,
  Footer,
}) => {
  const texts = useTexts();
  const {
    loading: isAssetListingsLoading,
    error: assetListingsError,
    assetListings,
    selectedAssetListing,
  } = useAssetListingsSubscription(state);

  const {
    loading: isAssetsLoading,
    error: assetsError,
    assets,
  } = useAssetsSubscription(selectedAssetListing);

  const panelRef = useRef<ExpandablePanel>(null);

  const onAssetListingChange = useCallback(
    (assetListing: AssetListing) => {
      dispatch({ type: "SelectAssetListingId", value: assetListing.id });
    },
    [dispatch]
  );

  const onBankConnectionsClick = useCallback(() => {
    panelRef.current?.open();
  }, [panelRef]);

  if (assetListingsError) {
    return onError(assetListingsError);
  }

  if (assetsError) {
    return onError(assetsError);
  }

  return (
    <>
      <header>
        <h1>{texts.assets}</h1>
      </header>
      <div className="assets-view">
        {isAssetListingsLoading ? (
          <div className="loading-container">
            <LoadingSpinner />
          </div>
        ) : (
          <AssetsViewInner
            onChange={onAssetListingChange}
            selectedAssetListing={selectedAssetListing}
            assetListings={assetListings}
            assets={assets}
            loading={isAssetsLoading}
          />
        )}
      </div>
      <ExpandablePanel
        expandedWidth="18em"
        expandDirection="right"
        ref={panelRef}
        className="bankconnections-panel"
        showCloseButton={false}
      >
        <AssetsConnectionsTable />
      </ExpandablePanel>
      <Footer>
        <CopyAsCsvButton
          assetListing={selectedAssetListing}
          style={{
            visibility: isAssetListingsLoading ? "hidden" : "visible",
          }}
        />
        <IconButton
          onMouseDown={onBankConnectionsClick}
          icon="fa-university"
          text={texts.banks}
          style={{
            visibility: isAssetListingsLoading ? "hidden" : "visible",
          }}
        />
      </Footer>
    </>
  );
};
