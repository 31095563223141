import _ from "lodash";
import {
  ASSETS_SUBSCRIPTION,
  ASSET_LISTINGS_SUBSCRIPTION,
} from "../../graphql/graphql";
import { useSelectedAssetListing } from "../../state/assets/hooks";
import { Asset, AssetListing, AssetState } from "../../state/assets/state";
import { ApolloError, useSubscription } from "@apollo/client";
import { AssetListingsSubscription } from "../../graphql/__generated__/AssetListingsSubscription";
import {
  AssetsSubscription,
  AssetsSubscriptionVariables,
} from "../../graphql/__generated__/AssetsSubscription";

type AssetsListingSubscriptionResult = {
  loading: boolean;
  error: ApolloError | undefined;
  assetListings: AssetListing[];
  selectedAssetListing: AssetListing | null;
};

type AssetsSubscriptionResult = {
  loading: boolean;
  error: ApolloError | undefined;
  assets: Asset[];
};

export const useAssetListingsSubscription = (
  state: AssetState
): AssetsListingSubscriptionResult => {
  const { data, loading, error } = useSubscription<AssetListingsSubscription>(
    ASSET_LISTINGS_SUBSCRIPTION
  );
  const assetListings = _.orderBy(
    data ? data.asset_listings : [],
    "date",
    "desc"
  );

  const selectedAssetListing =
    useSelectedAssetListing(state, assetListings) || assetListings[0] || null;

  return {
    loading,
    error,
    assetListings,
    selectedAssetListing,
  };
};

export const useAssetsSubscription = (
  assetListing: AssetListing | null
): AssetsSubscriptionResult => {
  const hasAssetListingId = !!assetListing;
  const { data, loading, error } = useSubscription<
    AssetsSubscription,
    AssetsSubscriptionVariables
  >(ASSETS_SUBSCRIPTION, {
    variables: {
      asset_listing_id: assetListing?.id as number,
    },
    skip: !hasAssetListingId,
  });

  const assets = data ? data.assets : [];

  return {
    loading,
    error,
    assets,
  };
};
