import React, { useCallback } from "react";
import {
  getPieSeries,
  pieGroupingFunction,
  pieLabelFunction,
  pieSliceColor,
} from "../transactionPieChartFunctions";
import { ZoomLevel } from "../../../state/spending/state";
import { PieChart } from "../../common/PieChart";
import { Slice } from "../../common/types";
import { currencyFormat } from "../../common/functions";
import { useColors } from "../../common/hooks";
import { ViewTransaction } from "../../../state/spending/selectors";

export type Props = {
  level: ZoomLevel;
  transactions: readonly ViewTransaction[];
  sources: readonly string[];
  onMouseDown: (slice: Slice) => void;
  labelBottom?: string;
};

const formatLegendValue = (value: number) => `${currencyFormat.format(value)}`;

export const TransactionPieChart: React.FunctionComponent<Props> = ({
  level,
  transactions,
  sources,
  onMouseDown,
  labelBottom,
}) => {
  const groupingFunction = useCallback(
    (t: ViewTransaction): string => pieGroupingFunction(level, t),
    [level]
  );

  const labelFunction = useCallback(
    (category: string): string => pieLabelFunction(level, category),
    [level]
  );
  const colors = useColors();

  const sliceColor = useCallback(
    (slice: Slice): string => pieSliceColor(colors, level, sources, slice),
    [level, colors, sources]
  );

  const series = getPieSeries(transactions, groupingFunction, labelFunction);

  const placeholderSeries: Slice = {
    id: "placeholder",
    name: "",
    value: 0.0001,
    showInLegend: false,
  };

  return (
    <PieChart
      series={series.length > 0 ? series : [placeholderSeries]}
      onMouseDown={onMouseDown}
      sliceColor={sliceColor}
      formatLegendValue={formatLegendValue}
      labelBottom={labelBottom}
    />
  );
};
