import { useMemo } from "react";
import { getSelectedAssetListing } from "./selectors";
import { AssetListing, AssetState } from "./state";

export const useSelectedAssetListing = (
  state: AssetState,
  assetListings: readonly AssetListing[]
) =>
  useMemo(
    () => getSelectedAssetListing(state, assetListings),
    [state, assetListings]
  );
