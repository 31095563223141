import React, { useCallback } from "react";
import { TransactionItemList } from "../../components/spending/TransactionItemList";
import { SpendingViewProps } from "./types";
import { useSelectedTransaction } from "../../state/spending/hooks";
import { ViewTransaction } from "../../state/spending/selectors";

export const TransactionsView: React.FunctionComponent<SpendingViewProps> = ({
  dispatch,
  state,
  transactions,
}) => {
  const handleClick = useCallback(
    (transaction: ViewTransaction) => {
      dispatch({ type: "SelectTransactionId", transactionId: transaction.id });
    },
    [dispatch]
  );

  const selectedTransaction = useSelectedTransaction(state, transactions);

  return (
    <TransactionItemList
      transactions={transactions}
      onMouseDown={handleClick}
      selectedTransaction={selectedTransaction}
    />
  );
};
