import {
  Colors,
  Texts,
  useColors,
  useTexts,
} from "../../components/common/hooks";
import { SpendingNavigationView } from "../../state/appState";
import {
  SpendingAction,
  SpendingState,
  ZoomLevel,
} from "../../state/spending/state";
import { customStyles, OptionType } from "../../components/common/reactSelect";
import Select from "react-select";
import {
  getCategoryGroup,
  getCategoryGroupLabel,
  getCategoryLabel,
} from "../../state/spending/transactionCategoryLabels";

type Props = {
  activeView: SpendingNavigationView;
  state: SpendingState;
  dispatch: React.Dispatch<SpendingAction>;
};

const getCategoryText = (level: ZoomLevel, texts: Texts): string => {
  switch (level.type) {
    case "AllCategories":
    case "AllSources":
      return "";
    case "Source":
      return `/ ${level.source}`;
    case "CategoryGroup":
      return `/ ${getCategoryGroupLabel(level.group)}`;
    case "Category":
      const categoryGroup = getCategoryGroup(level.category);
      return categoryGroup
        ? `/ ${getCategoryGroupLabel(categoryGroup)} / ${getCategoryLabel(
            level.category
          )}`
        : texts.unknown;
  }
};

const getPageHeaderText = (
  activeView: SpendingNavigationView,
  state: SpendingState,
  texts: Texts
): string | null => {
  switch (activeView) {
    case "YearlySpending":
    case "MonthlySpending":
    case "AverageSpending":
      return getCategoryText(state.level, texts);
    case "Transactions":
      return texts.transactions;
    case "Income":
      return texts.income;
    default:
      return null;
  }
};

const sourcesGroupingOption = { value: "sources", label: "Sources" };
const categoriesGroupingOption = (texts: Texts): OptionType => ({
  value: "categories",
  label: texts.categories,
});

const groupingOptions = (texts: Texts): OptionType[] => [
  categoriesGroupingOption(texts),
  sourcesGroupingOption,
];

const getSelectedGroupingOption = (
  state: SpendingState,
  texts: Texts
): OptionType => {
  switch (state.level.type) {
    case "AllCategories":
    case "Category":
    case "CategoryGroup":
      return categoriesGroupingOption(texts);
    default:
      return sourcesGroupingOption;
  }
};

const averageNumMonthsOptions = (texts: Texts): OptionType[] =>
  [3, 6, 12].map((months) => ({
    value: months.toString(),
    label: texts.xMonths.replace("%MONTHS%", months.toString()),
  }));

const getSelectedNumMonthsOption = (
  state: SpendingState,
  texts: Texts
): OptionType | undefined =>
  averageNumMonthsOptions(texts).find(
    (option) => option.value === state.averageSpendingNumMonths.toString()
  );

const getHeaderContent = (
  activeView: SpendingNavigationView,
  state: SpendingState,
  colors: Colors,
  texts: Texts,
  dispatch: React.Dispatch<SpendingAction>
): React.ReactNode => {
  const handleGroupingChange = (option: OptionType | null) => {
    if (option !== null) {
      switch (option.value) {
        case sourcesGroupingOption.value: {
          dispatch({ type: "SelectAllSources" });
          break;
        }
        case categoriesGroupingOption(texts).value: {
          dispatch({ type: "SelectAllCategories" });
          break;
        }
      }
    }
  };

  const handleAverageNumMonthsChange = (option: OptionType | null) => {
    if (option !== null) {
      dispatch({
        type: "SetAverageSpendingNumMonths",
        months: parseInt(option.value, 10),
      });
    }
  };

  const selectStyles = customStyles(
    colors,
    { display: "inline-block", marginBottom: "0.2em" },
    "8.5em",
    "8.5em"
  );

  switch (activeView) {
    case "YearlySpending":
    case "MonthlySpending":
      return (
        <>
          <Select
            styles={selectStyles}
            value={getSelectedGroupingOption(state, texts)}
            options={groupingOptions(texts)}
            onChange={handleGroupingChange}
            blurInputOnSelect={true}
            isSearchable={false}
          />
          <div style={{ height: "1em" }}>
            {getPageHeaderText(activeView, state, texts)}
          </div>
        </>
      );
    case "AverageSpending":
      return (
        <>
          <Select
            styles={selectStyles}
            value={getSelectedGroupingOption(state, texts)}
            options={groupingOptions(texts)}
            onChange={handleGroupingChange}
            blurInputOnSelect={true}
            isSearchable={false}
          />
          &nbsp;
          <Select
            styles={selectStyles}
            value={getSelectedNumMonthsOption(state, texts)}
            options={averageNumMonthsOptions(texts)}
            onChange={handleAverageNumMonthsChange}
            blurInputOnSelect={true}
            isSearchable={false}
          />
          <div style={{ height: "1em" }}>
            {getPageHeaderText(activeView, state, texts)}
          </div>
        </>
      );
    default:
      return getPageHeaderText(activeView, state, texts);
  }
};

export const SpendingViewHeader: React.FC<Props> = ({
  activeView,
  state,
  dispatch,
}) => {
  const texts = useTexts();
  const colors = useColors();

  const headerContent = getHeaderContent(
    activeView,
    state,
    colors,
    texts,
    dispatch
  );

  return (
    <header>
      <h1>{headerContent}</h1>
    </header>
  );
};
