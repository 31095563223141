import { format } from "date-fns";
import React, { useCallback } from "react";
import { currencyFormatWithSign } from "../../common/functions";
import { TransactionCategorySelect } from "../TransactionCategorySelect";
import { UPDATE_MANUAL_CATEGORY } from "../../../graphql/graphql";
import { ViewTransaction } from "../../../state/spending/selectors";
import { TransactionCategory } from "../../../state/spending/state";
import {
  UpdateManualCategory,
  UpdateManualCategoryVariables,
} from "../../../graphql/__generated__/UpdateManualCategory";
import { useColors } from "../../common/hooks";
import { useMutation } from "@apollo/client";
import { categoryGroupColor } from "../../../state/spending/transactionCategoryLabels";

import "./TransactionDetails.css";
import classNames from "classnames";

type Props = { transaction: ViewTransaction };

export const TransactionDetails: React.FunctionComponent<Props> = ({
  transaction,
}) => {
  const [updateManualCategory] = useMutation<
    UpdateManualCategory,
    UpdateManualCategoryVariables
  >(UPDATE_MANUAL_CATEGORY);

  const handleCategoryChange = useCallback(
    (newValue: TransactionCategory) => {
      updateManualCategory({
        variables: { id: transaction.id, category: newValue },
        refetchQueries: ["TransactionsQuery"],
      });
    },
    [transaction, updateManualCategory]
  );
  const colors = useColors();

  const dayAndTimeFormatted = format(transaction.date, "EEEE HH:mm");
  const dateFormatted = format(transaction.date, "d MMMM, yyyy");

  const color = categoryGroupColor(colors, transaction.categoryGroup);

  const hasModifiedAmount = transaction.amount !== transaction.originalAmount;
  const amountClassName = classNames({
    strikethrough: hasModifiedAmount,
  });

  return (
    <div className="transaction-details-content">
      <div style={{ gridColumn: "2" }}>{transaction.description}</div>
      <div
        style={{
          gridColumn: 1,
          display: "flex",
          alignItems: "center",
          paddingRight: "0.6em",
        }}
      >
        <div className="item-box" style={{ backgroundColor: color }}></div>
      </div>
      <TransactionCategorySelect
        value={transaction.category}
        onChange={handleCategoryChange}
        style={{
          marginLeft: "-5px",
          marginTop: "5px",
          fontSize: "85%",
          gridColumn: "2",
        }}
      />
      <div style={{ gridColumn: "2" }}>
        <span className={amountClassName}>
          {currencyFormatWithSign.format(transaction.originalAmount)}
        </span>
        {hasModifiedAmount ? (
          <>
            &nbsp;
            {currencyFormatWithSign.format(transaction.amount)}
          </>
        ) : null}
      </div>
      <div
        className="transactions-details-date"
        style={{ gridColumn: "2" }}
        title={transaction.date.toISOString()}
      >
        {dayAndTimeFormatted}
        <br />
        {dateFormatted}
      </div>
    </div>
  );
};
