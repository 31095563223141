import { SpendingAction, SpendingState } from "./state";
import { getCategoryGroup } from "./transactionCategoryLabels";
import {
  getNextMonth,
  getPreviousMonth,
  hasNextMonth,
  hasPreviousMonth,
} from "./selectors";

export const reducer = (
  state: SpendingState,
  action: SpendingAction
): SpendingState => {
  switch (action.type) {
    case "SelectCategory":
      return {
        ...state,
        level: { type: "Category", category: action.category },
      };
    case "SelectGroup":
      return {
        ...state,
        level: { type: "CategoryGroup", group: action.group },
      };
    case "SelectSource": {
      return {
        ...state,
        level: { type: "Source", source: action.source },
      };
    }
    case "SelectAllCategories": {
      return {
        ...state,
        level: { type: "AllCategories" },
      };
    }
    case "SelectAllSources": {
      return {
        ...state,
        level: { type: "AllSources" },
      };
    }
    case "Back": {
      switch (state.level.type) {
        case "AllCategories":
        case "AllSources":
          return state;
        case "Source":
          return { ...state, level: { type: "AllSources" } };
        case "CategoryGroup":
          return { ...state, level: { type: "AllCategories" } };
        case "Category":
          const categoryGroup = getCategoryGroup(state.level.category);

          return categoryGroup
            ? {
                ...state,
                level: {
                  type: "CategoryGroup",
                  group: categoryGroup,
                },
              }
            : state;
      }
      break;
    }
    case "PreviousMonth": {
      const date = hasPreviousMonth(state, action.minDate)
        ? getPreviousMonth(state)
        : state.date;

      return {
        ...state,
        date,
      };
    }
    case "NextMonth": {
      const date = hasNextMonth(state, action.maxDate)
        ? getNextMonth(state)
        : state.date;

      return {
        ...state,
        date,
      };
    }
    case "PreviousYear": {
      const previousYear = state.date.year - 1;

      if (previousYear < action.minDate.year) {
        return state;
      }

      return {
        ...state,
        date: { year: previousYear, month: state.date.month },
      };
    }
    case "NextYear": {
      const nextYear = state.date.year + 1;

      if (nextYear > action.maxDate.year) {
        return state;
      }

      return {
        ...state,
        date: { year: nextYear, month: state.date.month },
      };
    }
    case "SetDate": {
      return { ...state, date: action.date };
    }
    case "SelectTransactionId": {
      return {
        ...state,
        selectedTransactionId: action.transactionId,
      };
    }
    case "SetAverageSpendingNumMonths": {
      return {
        ...state,
        averageSpendingNumMonths: action.months,
      };
    }
  }
};
