import { useCallback } from "react";
import { format } from "date-fns";
import _ from "lodash";
import { SpendingViewProps } from "./types";
import { Slice } from "../../components/common/types";
import { BarChartValue } from "../../components/common/BarChart";
import { toJavascriptDate, yearMonthFromString } from "../../common/yearMonth";
import { currencyFormat } from "../../components/common/functions";
import { onPieClick } from "../../components/spending/transactionPieChartFunctions";
import { TransactionPieChart } from "../../components/spending/TransactionPieChart";
import { TransactionMonthBarChart } from "../../components/spending/TransactionMonthBarChart";
import { TransactionItemList } from "../../components/spending/TransactionItemList";
import {
  useSelectedTransaction,
  useTransactionsMatchingDateFilterAndZoomLevel,
} from "../../state/spending/hooks";
import {
  TransactionCategory,
  TransactionCategoryGroup,
} from "../../state/spending/state";
import { ViewTransaction } from "../../state/spending/selectors";

export const MonthlySpendingView: React.FunctionComponent<
  SpendingViewProps
> = ({ state, dispatch, transactions, sources }) => {
  const filteredTransactions = transactions.filter(
    (t) =>
      t.amount < 0 &&
      t.category !== TransactionCategory.Transfers &&
      t.categoryGroup !== TransactionCategoryGroup.Savings
  );
  const transactionsMatchingDateFilterAndZoomLevel =
    useTransactionsMatchingDateFilterAndZoomLevel(state, filteredTransactions);

  const selectedTransaction = useSelectedTransaction(
    state,
    filteredTransactions
  );

  const handlePieClick = useCallback(
    (slice: Slice): void => onPieClick(state, slice, dispatch),
    [state, dispatch]
  );

  const handleBarClick = useCallback(
    (value: BarChartValue) =>
      dispatch({ type: "SetDate", date: yearMonthFromString(value.id) }),
    [dispatch]
  );

  const handleTransactionMouseDown = useCallback(
    (transaction: ViewTransaction) => {
      dispatch({ type: "SelectTransactionId", transactionId: transaction.id });
    },
    [dispatch]
  );

  const monthFormatted = format(toJavascriptDate(state.date), "MMMM yyyy");
  const sumFormatted = currencyFormat.format(
    _.sumBy(transactionsMatchingDateFilterAndZoomLevel, (t) => -t.amount)
  );

  const isTransactionsLevel =
    state.level.type === "Category" || state.level.type === "Source";

  const pieChartLabel = format(toJavascriptDate(state.date), "MMMM yyyy");

  return (
    <>
      {isTransactionsLevel ? (
        <div style={{ flex: 1, overflowY: "scroll" }}>
          <div style={{ fontWeight: "bold", fontSize: "150%" }}>
            {sumFormatted}
          </div>
          <div style={{ marginBottom: "0.6em" }}>{monthFormatted}</div>
          <TransactionItemList
            transactions={transactionsMatchingDateFilterAndZoomLevel}
            onMouseDown={handleTransactionMouseDown}
            selectedTransaction={selectedTransaction}
          />
        </div>
      ) : (
        <div className="chart-container">
          <div className="pie-chart-container">
            <TransactionPieChart
              transactions={transactionsMatchingDateFilterAndZoomLevel}
              sources={sources}
              level={state.level}
              onMouseDown={handlePieClick}
              labelBottom={pieChartLabel}
            />
          </div>
          <div className="bar-chart-container">
            <TransactionMonthBarChart
              transactions={filteredTransactions}
              state={state}
              onMouseDown={handleBarClick}
            />
          </div>
        </div>
      )}
    </>
  );
};
