import classnames from "classnames";
import { useCallback, useState } from "react";
import "./IconButton.css";

type Props = {
  icon: string;
  text: string;
  disabled?: boolean;
  fontSize?: string;
  rotation?: number;
  flash?: boolean;
  onMouseDown: () => void;
  style?: React.CSSProperties;
};

export const IconButton: React.FunctionComponent<Props> = ({
  icon,
  text,
  disabled,
  fontSize,
  rotation,
  flash,
  onMouseDown,
  style,
}: Props) => {
  const [isFlashing, setIsFlashing] = useState(false);

  const handleOnMouseDown = useCallback(() => {
    setIsFlashing(true);
    if (onMouseDown) {
      onMouseDown();
    }

    setTimeout(() => setIsFlashing(false), 800);
  }, [onMouseDown]);

  const buttonClass = classnames({
    "icon-button": true,
    "icon-button-flash": isFlashing,
  });

  return (
    <button
      className={buttonClass}
      onMouseDown={flash ? handleOnMouseDown : onMouseDown}
      style={style}
      disabled={disabled}
    >
      <span
        style={{
          fontSize: fontSize || "300%",
          transform: `rotate(${rotation || 0}deg)`,
        }}
      >
        <i className={`fas ${icon}`}></i>
      </span>
      <span className="icon-button-text">{text}</span>
    </button>
  );
};
