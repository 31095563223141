import React from "react";
import { IconButton } from "../../components/common/IconButton";

type Props = {
  imgUrl: string;
  imgAltText: string;
  name: string;
  onClick: () => void;
};

export const BankSyncButton: React.FC<Props> = ({
  imgUrl,
  imgAltText,
  name,
  onClick,
}) => (
  <>
    <div>
      <img src={imgUrl} alt={imgAltText} />
    </div>
    <div>{name}</div>
    <div>
      <IconButton
        onMouseDown={onClick}
        icon="fa-sync-alt"
        text="Sync"
        fontSize="180%"
      />
    </div>
  </>
);
