import _ from "lodash";
import React from "react";
import { percentFormat } from "../functions";
import { Slice } from "../types";
import "./PieChartLegend.css";

type Props = {
  series: readonly Slice[];
  formatValue: (value: number) => string;
  sliceColor: (slice: Slice, index: number) => string;
  onMouseDown?: (slice: Slice) => void;
};

export const PieChartLegend: React.FunctionComponent<Props> = ({
  series,
  formatValue,
  sliceColor,
  onMouseDown,
}: Props) => {
  const sum = _.sumBy(series, "value");
  const seriesReversed = _.reverse(_.clone(series));

  return (
    <div className="legend">
      {seriesReversed.map((slice, index) => {
        const color = sliceColor(slice, index);
        const valuePercent = slice.value / sum;
        return (
          <div
            key={slice.id}
            className="legend-item"
            onMouseDown={() => onMouseDown && onMouseDown(slice)}
          >
            <div
              className="legend-box"
              style={{ backgroundColor: color }}
            ></div>
            <div className="legend-name">{slice.name}</div>
            <div className="legend-value">{formatValue(slice.value)}</div>
            <div className="legend-value-percent">
              ({percentFormat.format(valuePercent)})
            </div>
          </div>
        );
      })}
    </div>
  );
};
