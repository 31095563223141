import React from "react";
import _ from "lodash";
import { LoadingSpinner } from "../../components/common/LoadingSpinner";
import {
  AssetsQuery,
  AssetsQueryVariables,
} from "../../graphql/__generated__/AssetsQuery";
import { Asset } from "../../state/assets/state";
import { currencyFormat } from "../../components/common/functions";

import "./AssetsTable.css";
import { ApolloQueryResult } from "@apollo/client";
import { asset_types_enum } from "../../__generated__/graphql-global-types";
import { getAssetTypeLabel } from "../../state/assets/assetTypeLabels";

export type AssetRefetch = (
  variables: Partial<AssetsQueryVariables> | undefined
) => Promise<ApolloQueryResult<AssetsQuery>>;

type Props = {
  assets: Asset[];
  loading: boolean;
};

const getIconBySource = (source: string): JSX.Element | null => {
  const size = 20;
  switch (source) {
    case "Nordnet":
      return (
        <img
          src="/nordnet-144x144.png"
          alt="Nordnet logo"
          width={size}
          height={size}
        />
      );
    case "Swedbank":
      return (
        <img
          src="/swedbank-144x144.png"
          alt="Swedbank logo"
          width={size}
          height={size}
        />
      );
    case "Avanza":
      return (
        <img
          src="/avanza-144x144.png"
          alt="Avanza logo"
          width={size}
          height={size}
        />
      );
    case "Rocker":
      return (
        <img
          src="/rocker-144x144.png"
          alt="Rocker logo"
          width={size}
          height={size}
        />
      );
    case "Lansforsakringar":
      return (
        <img
          src="/lansforsakringar-144x144.png"
          alt="Länsförsäkringar logo"
          width={size}
          height={size}
        />
      );
    case "Northmill":
      return (
        <img
          src="/northmill-144x144.png"
          alt="Northmill logo"
          width={size}
          height={size}
        />
      );
    default:
      return null;
  }
};

export const AssetsTable: React.FunctionComponent<Props> = ({
  assets,
  loading,
}) => {
  if (loading) {
    return (
      <div className="loading-container">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="asset-table-scroll-container">
      {_.chain(assets)
        .orderBy("type")
        .groupBy((asset: Asset) => asset.type)
        .map((assets: Asset[], type: asset_types_enum) => {
          const sum = _.sumBy(
            assets,
            (asset) => asset.market_value - asset.liability
          );

          const assetsSorted = _.orderBy(assets, ["source", "name"]);

          return (
            <React.Fragment key={type}>
              <h3>
                <span>{getAssetTypeLabel(type)}</span>
                <span>{currencyFormat.format(sum)}</span>
              </h3>
              <div className="assets-table">
                {assetsSorted.map((asset: Asset) => (
                  <React.Fragment key={`asset-${asset.id}`}>
                    <div className="image-container">
                      {getIconBySource(asset.source)}
                    </div>
                    <div title={asset.name}>{asset.name}</div>
                    <div>
                      {currencyFormat.format(
                        asset.market_value - asset.liability
                      )}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </React.Fragment>
          );
        })
        .value()}
    </div>
  );
};
