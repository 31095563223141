import { AppAction, AppState } from "./appState";
import { reducer as spendingReducer } from "./spending/reducer";
import { reducer as assetReducer } from "./assets/reducer";

// TODO: This will only work if we have one other reducer?
export const reducer = (state: AppState, action: AppAction): AppState => {
  switch (action.slice) {
    case "App": {
      switch (action.type) {
        case "NavigateToView":
          return {
            ...state,
            activeView: action.view,
          };
      }
      break;
    }
    case "Spending": {
      return {
        ...state,
        spendingState: spendingReducer(state.spendingState, action.action),
      };
    }
    case "Assets": {
      return {
        ...state,
        assetState: assetReducer(state.assetState, action.action),
      };
    }
  }
};
