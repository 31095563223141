import React, { PropsWithChildren } from "react";
import { useTexts } from "../common/hooks";

import "./ConnectionsTable.css";

export const ConnectionsTable: React.FC<PropsWithChildren> = ({ children }) => {
  const texts = useTexts();

  return (
    <div className="connections">
      <h2>{texts.banks}</h2>
      <div className="connections-table">{children}</div>
    </div>
  );
};
