import _ from "lodash";
import { useCallback } from "react";
import { comparisonValue, YearMonth } from "../../common/yearMonth";
import { BarChartValue } from "../../components/common/BarChart";
import { currencyFormat } from "../../components/common/functions";
import { Slice } from "../../components/common/types";
import { TransactionItemList } from "../../components/spending/TransactionItemList";
import { TransactionPieChart } from "../../components/spending/TransactionPieChart";
import { onPieClick } from "../../components/spending/transactionPieChartFunctions";
import { TransactionYearBarChart } from "../../components/spending/TransactionYearBarChart";
import {
  useSelectedTransaction,
  useTransactionMaxDate,
  useTransactionMinDate,
  useTransactionsMatchingYearFilterAndZoomLevel,
} from "../../state/spending/hooks";
import { ViewTransaction } from "../../state/spending/selectors";
import {
  TransactionCategory,
  TransactionCategoryGroup,
} from "../../state/spending/state";
import { SpendingViewProps } from "./types";

const getNewDate = (
  value: BarChartValue,
  currentDate: YearMonth,
  minDate: YearMonth,
  maxDate: YearMonth
): YearMonth => {
  var candidate = { year: parseInt(value.id, 10), month: currentDate.month };

  if (comparisonValue(candidate) < comparisonValue(minDate)) {
    return minDate;
  }

  if (comparisonValue(candidate) > comparisonValue(maxDate)) {
    return maxDate;
  }

  return candidate;
};

export const YearlySpendingView: React.FunctionComponent<SpendingViewProps> = ({
  state,
  dispatch,
  transactions,
  sources,
}) => {
  const isTransactionsLevel =
    state.level.type === "Category" || state.level.type === "Source";

  const filteredTransactions = transactions.filter(
    (t) =>
      t.amount < 0 &&
      t.category !== TransactionCategory.Transfers &&
      t.categoryGroup !== TransactionCategoryGroup.Savings
  );

  const minDate = useTransactionMinDate(filteredTransactions);
  const maxDate = useTransactionMaxDate(filteredTransactions);

  const selectedTransaction = useSelectedTransaction(
    state,
    filteredTransactions
  );

  const handleBarClick = useCallback(
    (value: BarChartValue) =>
      dispatch({
        type: "SetDate",
        date: getNewDate(value, state.date, minDate, maxDate),
      }),
    [dispatch, maxDate, minDate, state.date]
  );

  const handlePieClick = useCallback(
    (slice: Slice): void => onPieClick(state, slice, dispatch),
    [state, dispatch]
  );

  const handleTransactionMouseDown = useCallback(
    (transaction: ViewTransaction) => {
      dispatch({ type: "SelectTransactionId", transactionId: transaction.id });
    },
    [dispatch]
  );

  const matchingTransactions = useTransactionsMatchingYearFilterAndZoomLevel(
    state,
    filteredTransactions
  );

  const sumFormatted = currencyFormat.format(
    _.sumBy(matchingTransactions, (t) => -t.amount)
  );

  return isTransactionsLevel ? (
    <div style={{ flex: 1, overflowY: "scroll" }}>
      <div style={{ fontWeight: "bold", fontSize: "150%" }}>{sumFormatted}</div>
      <div style={{ marginBottom: "0.6em" }}>{state.date.year}</div>
      <TransactionItemList
        transactions={matchingTransactions}
        onMouseDown={handleTransactionMouseDown}
        selectedTransaction={selectedTransaction}
      />
    </div>
  ) : (
    <div className="chart-container">
      <div className="pie-chart-container">
        <TransactionPieChart
          transactions={matchingTransactions}
          sources={sources}
          level={state.level}
          onMouseDown={handlePieClick}
          labelBottom={state.date.year.toString()}
        />
      </div>
      <div className="bar-chart-container">
        <TransactionYearBarChart
          transactions={filteredTransactions}
          state={state}
          onMouseDown={handleBarClick}
        />
      </div>
    </div>
  );
};
